import { Flex } from "@chakra-ui/react";
import { ComicThumbnail } from "../../../../components/comics/ComicThumbnail";
import { LibraryToggles } from "./LibraryToggles";
import { LibraryStatusRadio } from "./LibraryStatusRadio";
import { useAddToMenu } from "../../hooks/useAddToMenu";
import { ImmutableObject } from "@hookstate/core";
import { Comic } from "../../../../types";
import { SubmitButton } from "./SubmitButton";
import { useComicData } from "../../hooks/hookstate/useComicData";
import { SLIDE_IN_MENUS } from "../../../root/constants";
import { SlideInHeader } from "../../../../components/slideInMenu/components/SlideInHeader";

export function AddToMenuWrapper() {
  const { comicData } = useComicData();
  return <AddToMenu comicData={comicData} />;
}

interface AddToMenuInnerProps {
  comicData: ImmutableObject<Comic> | undefined;
  onCloseMenu?: () => void;
}
export function AddToMenu({ comicData, onCloseMenu }: AddToMenuInnerProps) {
  const {
    status,
    setLibraryStatus,
    disableEmails,
    toggleDisableEmails,
    disableNotifications,
    toggleDisableNotifications,
    mutateLibrary,
    removeFromLibrary,
  } = useAddToMenu(comicData);

  return (
    <>
      <SlideInHeader
        openMenu={SLIDE_IN_MENUS.addTo}
        onCloseMenu={onCloseMenu}
      />
      <Flex
        position="relative"
        height="100%"
        width="100%"
        flexDirection="column"
        justifyContent="space-between"
      >
        <Flex
          p="24px"
          overflowY="auto"
          maxWidth="100%"
          height="calc(100% - 80px)"
          width="100%"
          flexDirection="column"
          position="relative"
          justifyContent="flex-start"
          gap="24px"
        >
          <ComicThumbnail comicData={comicData} />
          <LibraryStatusRadio
            value={status}
            setLibraryStatus={setLibraryStatus}
          />
          <LibraryToggles
            disableEmails={disableEmails}
            toggleDisableEmails={toggleDisableEmails}
            disableNotifications={disableNotifications}
            toggleDisableNotifications={toggleDisableNotifications}
          />
        </Flex>
        <SubmitButton
          inLibrary={!!comicData?.library_status}
          mutateLibrary={mutateLibrary}
          removeFromLibrary={removeFromLibrary}
        />
      </Flex>
    </>
  );
}
