import { Text, Button, Flex, Spinner } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { SubscriptionSelector } from "./components/SubscriptionSelector";
import { PaymentTitle } from "./components/PaymentTitle";
import { PaymentValueProps } from "./components/PaymentValueProps";
import { PaymentEyebrow } from "./components/PaymentEyebrow";
import { CloseIcon } from "@chakra-ui/icons";
import { t } from "i18next";
import { useAccountData } from "../../hooks/useAccountData";
import { ReaderLockedPages } from "./components/LockedPages";
import {
  GET_SUBSCRIPTION_PRICING_QUERY_KEY,
  GET_GIFTCARD_PRICING_QUERY_KEY,
  getSubscriptionPricing,
  getGiftCardPricing,
  webPurchase,
  WebPurchasePayloadTypes,
} from "./api/paymentApi";
import {
  StripePayment,
  StripePaymentConfigTypes,
} from "./components/StripePayment";
import { PromoCodeInput } from "./components/PromoCodeInput";
import { useInvalidateQueries } from "../../services/axiosInstance";
import { ACCOUNT_INIT_QUERY_KEY } from "../../appStore";
import { FunnelComponentProps } from "../funnel/types";
import axios from "axios";
import { ServerResponse } from "../../types";
import {
  SignUpModalBody,
  SignUpModalFooter,
} from "../auth/components/SignUpModal";
import { useUserData } from "../../hooks/useUserData";
import { FUNNEL_TYPES, FUNNEL_TYPES_TYPES } from "../funnel/utils/contants";
import {
  LoginModalBody,
  LoginModalFooter,
} from "../auth/components/LoginModal";
import {
  SignUpWithEmailPasswordModalBody,
  SignUpWithEmailPasswordModalFooter,
} from "../auth/components/SignUpWithEmailPasswordModal";
import { SubscriptionPriceTypes } from "./types";
import { useIsSmallBreakpoint } from "../../utils/useBreakpoints";
import { usePaymentMutation } from "./hooks/usePaymentMutation";
import { hookstate } from "@hookstate/core";
import { EventTypes } from "../../screens/root/api/eventsApi";
import { useIsVisible } from "../../hooks/useIsVisible";
import { useSearchParams } from "react-router-dom";
import { useReleaseDataQueryKey } from "../../screens/reader/hooks/useReleaseDataQueryKey";
import { useReadingDirection } from "../../screens/reader/hooks/useReadingDirection";
import { useIsSmallWindowHeight } from "../../utils/useIsSmallWindowHeight";
import { sendPurchaseEvent } from "../../hooks/useSendPurchaseEvent";

export function SubscriptionModalHeader({ onClose }: FunnelComponentProps) {
  return (
    <Flex justifyContent="space-between" flexDirection="row-reverse">
      <Button variant="iconOnly" onClick={onClose} minWidth={0}>
        <CloseIcon color="dune.100" />
      </Button>
    </Flex>
  );
}

// Global state that is used to store the pending auth payment config
export const pendingAuthPaymentConfig = hookstate<
  WebPurchasePayloadTypes | undefined
>(undefined);
export const SubscriptionModalBody = memo(
  ({
    context = "reader",
    isModal = false,
    hideCloseButton = false,
    onSuccess: onFunnelSuccess,
    onClose,
    sendEvent,
  }: FunnelComponentProps & {
    context?: string;
    hideCloseButton?: boolean;
  }) => {
    const accountData = useAccountData();
    const { userData } = useUserData();
    const [urlSearchParams] = useSearchParams();
    const [promoCode, setPromoCode] = useState<string>(
      urlSearchParams.get("code") ?? "",
    );
    const [appliedPromoCode, setAppliedPromoCode] = useState<string>(
      urlSearchParams.get("code") ?? "",
    );
    const [activeType, setActiveType] = useState<"yearly" | "monthly">();
    const [activeAuthType, setActiveAuthType] = useState<FUNNEL_TYPES_TYPES>();
    const releaseQueryKey = useReleaseDataQueryKey();
    const invalidateQueries = useInvalidateQueries(
      ACCOUNT_INIT_QUERY_KEY,
      releaseQueryKey as string[],
    );
    const isSmallBreakpoint = useIsSmallBreakpoint();
    const isSmallWindowHeight = useIsSmallWindowHeight();

    const [yearlySub, setYearlySub] = useState<SubscriptionPriceTypes>();
    const [monthlySub, setMonthlySub] = useState<SubscriptionPriceTypes>();

    const getPurchasePayload = useCallback(
      (stripePaymentConfig: StripePaymentConfigTypes) => {
        const { access_token_type_id, current_price, promo_code } =
          (activeType === "yearly" ? yearlySub : monthlySub) ?? {};
        return {
          ...stripePaymentConfig,
          accessTokenTypeId: access_token_type_id ?? 0,
          totalMinorUnits: Math.round((current_price ?? 0) * 100),
          incentiveCode: promoCode.length ? promoCode : promo_code,
        };
      },
      [activeType, yearlySub, monthlySub, promoCode],
    );

    const onSuccess = () => {
      setWaiting(true);
      invalidateQueries();
      if (!pendingAuthPaymentConfig.get()) onFunnelSuccess();
      const purchasedSubscription =
        activeType === "yearly" ? yearlySub : monthlySub;
      if (purchasedSubscription) {
        sendPurchaseEvent({
          value: purchasedSubscription.current_price,
          currency: purchasedSubscription.currency_code,
        });
      }
    };

    const onError = (error: Error) => {
      setActiveAuthType(undefined);
      let errorMessage = t(
        "components.payments.subscription.subscriptionDeclined",
      );

      if (axios.isAxiosError<ServerResponse<string>>(error)) {
        errorMessage = error.response?.data?.payload.results || errorMessage;
      }
      return errorMessage;
    };

    const isGiftContext = context === "gift";
    const selectedQueryKey = isGiftContext
      ? [...GET_GIFTCARD_PRICING_QUERY_KEY, appliedPromoCode]
      : [...GET_SUBSCRIPTION_PRICING_QUERY_KEY, appliedPromoCode];

    const {
      mutate,
      waiting,
      setWaiting,
      failedCard,
      cardValidationError,
      setCardValidationError,
    } = usePaymentMutation(webPurchase, onSuccess, onError);

    const { data } = useQuery({
      enabled: !waiting && !!accountData,
      queryKey: selectedQueryKey,
      queryFn: isGiftContext
        ? () => getGiftCardPricing(appliedPromoCode)
        : () => getSubscriptionPricing(appliedPromoCode),
      refetchOnWindowFocus: false,
    });

    useEffect(() => {
      const results = data?.data.payload.results;
      const subscriptions = results?.subscriptions;

      if (!subscriptions) return;

      setYearlySub(
        subscriptions.find((sub) => sub.billing_frequency === "yearly"),
      );
      setMonthlySub(
        subscriptions.find((sub) => sub.billing_frequency === "monthly"),
      );
    }, [data]);

    useEffect(() => {
      if (!activeType || !appliedPromoCode) {
        if (yearlySub?.promo_code) {
          setActiveType("yearly");
        } else if (monthlySub?.promo_code) {
          setActiveType("monthly");
        } else {
          setActiveType("yearly");
        }
      } else {
        if (appliedPromoCode === yearlySub?.promo_code) {
          setActiveType("yearly");
        } else if (appliedPromoCode == monthlySub?.promo_code) {
          setActiveType("monthly");
        }
      }
    }, [yearlySub, monthlySub]);

    const ref = useRef<HTMLDivElement>(null);
    const isVisible = useIsVisible(ref, 0.5);
    const alreadyTracked = useRef(false);

    useEffect(() => {
      if (sendEvent && isVisible && !alreadyTracked.current) {
        sendEvent(EventTypes.viewed_subscription_offer);
        alreadyTracked.current = true;
      }
    }, [isVisible]);

    const contextMap = {
      reader: "reader",
      modal: "modal",
      gold: "modal",
      gift: "modal",
      "nav-bar": "modal",
      start_next_release_button: "modal",
      "chat-schedule": "modal",
      "intro-offer": "modal",
    };
    const getActivePaywall = () => {
      const activePaywalls =
        activeType === yearlySub?.billing_frequency
          ? yearlySub?.paywalls
          : monthlySub?.paywalls;

      if (context === "intro-offer") {
        return activePaywalls?.find((paywall) => paywall.is_intro);
      }

      return activePaywalls?.find(
        (paywall) =>
          paywall.context === contextMap[context as keyof typeof contextMap],
      );
    };

    const [isPromoValid, setIsPromoValid] = useState(true);
    useEffect(() => {
      if ((!promoCode && !appliedPromoCode) || appliedPromoCode === promoCode) {
        setIsPromoValid(true);
        setCardValidationError(undefined);
        return;
      }
      const timeout = setTimeout(() => {
        setIsPromoValid(false);
        setCardValidationError(
          t("components.payments.subscription.applyPromoToComplete"),
        );
      }, 400);
      return () => clearTimeout(timeout);
    }, [promoCode, appliedPromoCode]);

    const onAuthSuccess = () => {
      setWaiting(true);
    };

    // Either send the purchase to the API or save the purchase payload
    // for after auth
    const submitPurchase = useCallback(
      (stripePaymentConfig: StripePaymentConfigTypes) => {
        if (!userData) {
          pendingAuthPaymentConfig.set(getPurchasePayload(stripePaymentConfig));
          setActiveAuthType(FUNNEL_TYPES.signup);
        } else {
          mutate(getPurchasePayload(stripePaymentConfig));
        }
      },
      [getPurchasePayload, mutate, userData],
    );

    // Determine if we need to use the pending payment config
    // If the user is gold we don't need to use the pending config
    // If the user is not gold we send the pending config to the api
    // and purchase.
    useEffect(() => {
      const pendingConfig = pendingAuthPaymentConfig.get({ noproxy: true });
      if (userData?.gold && pendingConfig) {
        onFunnelSuccess();
        pendingAuthPaymentConfig.set(undefined);
      } else if (pendingConfig) {
        mutate(pendingConfig);
        pendingAuthPaymentConfig.set(undefined);
      }
    }, [userData]);

    // Clear the pending config on unmount
    useEffect(() => {
      return () => {
        pendingAuthPaymentConfig.set(undefined);
      };
    }, []);

    const gold = context === "gold" || isGiftContext;
    const showSmallContent = isSmallBreakpoint || gold || isSmallWindowHeight;
    const goldLarge = gold && !isSmallBreakpoint;
    const { readingDirection } = useReadingDirection();
    const isVertical = readingDirection === "v" && context === "reader";

    const activePaywall = getActivePaywall();

    if (!data || !yearlySub || !monthlySub || !activePaywall) {
      return (
        <Flex
          width="100%"
          justifyContent="center"
          alignItems="center"
          height="531px"
          ref={ref}
        >
          <Spinner color="blaze.blaze" />
        </Flex>
      );
    }

    const isPPBEnable = accountData?.features["ppb_pre_paywall"] ?? false;
    const isNotInGoldOrGift = context !== "gold" && context !== "gift";

    const showX = isPPBEnable
      ? isSmallBreakpoint || isNotInGoldOrGift
      : (isSmallBreakpoint || context !== "reader") && isNotInGoldOrGift;

    return (
      <Flex
        flexDirection={goldLarge ? "row" : "column"}
        gap={showSmallContent ? "16px" : "24px"}
        alignItems="center"
        justifyContent={goldLarge ? "space-evenly" : "center"}
        borderRadius="16px"
        ref={ref}
      >
        <Flex
          flexDirection="column"
          width="fit-content"
          gap={gold ? "32px" : "24px"}
          alignItems={goldLarge ? "flex-start" : "center"}
          paddingBottom={gold ? "24px" : undefined}
        >
          {hideCloseButton || isVertical || !showX ? null : (
            <Flex justifyContent="flex-end" width="100%">
              <Button
                id="btnCloseSubscriptionModal"
                variant="iconOnly"
                onClick={onClose}
                minWidth={0}
              >
                <CloseIcon color="dune.100" />
              </Button>
            </Flex>
          )}
          {context === "reader" && !isPPBEnable && <ReaderLockedPages />}
          {activePaywall?.eyebrow && (
            <PaymentEyebrow alt={gold} text={activePaywall.eyebrow} />
          )}
          <PaymentTitle
            size={goldLarge ? "40px" : undefined}
            textAlign={goldLarge ? "left" : "center"}
            text={activePaywall?.main_title ?? ""}
          />
          <PaymentValueProps
            textColor="dune.dune"
            showSeparators={false}
            alignItems="start"
            valueProps={activePaywall?.value_props ?? ["", "", ""]}
            iconColor={gold ? "dune.dune" : undefined}
          />
        </Flex>
        <Flex
          flexDirection="column"
          maxWidth={goldLarge ? "500px" : "100%"}
          width="100%"
          gap={isSmallBreakpoint || gold ? "16px" : "24px"}
          padding={gold ? "24px" : undefined}
          alignItems={gold ? "flex-start" : "center"}
          background={
            gold
              ? "linear-gradient(346deg, rgba(20, 21, 23, 0.75) 66.94%, rgba(68, 90, 119, 0.11) 200.5%);"
              : undefined
          }
          borderColor={
            gold
              ? "1px solid var(--White-Transparency-White-10, rgba(255, 255, 255, 0.10));"
              : undefined
          }
          backdropFilter={gold ? "blur(18px)" : undefined}
          boxShadow={gold ? "0px 4px 24px 0px rgba(0, 0, 0, 0.40)" : undefined}
          borderRadius={gold ? "16px" : undefined}
        >
          {!activeAuthType ? (
            <>
              <Flex
                flexDirection="column"
                width="100%"
                gap="16px"
                paddingTop="14px"
              >
                <SubscriptionSelector
                  isSmall={showSmallContent}
                  onClick={setActiveType}
                  subscriptionPrice={yearlySub}
                  active={yearlySub?.billing_frequency === activeType}
                />
                <SubscriptionSelector
                  isSmall={showSmallContent}
                  onClick={setActiveType}
                  subscriptionPrice={monthlySub}
                  active={monthlySub?.billing_frequency === activeType}
                />
                <Text
                  color="neutral.300"
                  fontSize="12px"
                  lineHeight="150%"
                  textAlign="center"
                >
                  {t(
                    isGiftContext
                      ? "components.payments.subscription.giftDelivery"
                      : "components.payments.subscription.cancelAnytime",
                  )}
                </Text>
              </Flex>
              <PromoCodeInput
                isSmall={showSmallContent}
                promoApplied={appliedPromoCode}
                isValid={
                  appliedPromoCode === yearlySub?.promo_code ||
                  appliedPromoCode === monthlySub?.promo_code
                }
                applyPromoCode={setAppliedPromoCode}
                onChange={setPromoCode}
              />
              <StripePayment
                isSmall={showSmallContent}
                waiting={waiting}
                isDisabled={!isPromoValid}
                responseError={cardValidationError}
                failedCard={failedCard}
                submitButtonText={
                  userData
                    ? t(
                        isGiftContext
                          ? "components.payments.subscription.confirmGiftCardPayment"
                          : "components.payments.subscription.confirmYourPayment",
                      )
                    : t("components.payments.subscription.signUpToComplete")
                }
                completePurchase={submitPurchase}
                isModal={isModal}
                isGiftCard={isGiftContext}
              />
            </>
          ) : (
            <AuthFunnel
              activeAuthType={activeAuthType}
              setFunnelType={setActiveAuthType}
              onClose={onClose}
              onSuccess={onAuthSuccess}
              waiting={waiting}
              sendEvent={sendEvent}
            />
          )}
        </Flex>
      </Flex>
    );
  },
);

export function AuthFunnel({
  activeAuthType,
  setFunnelType,
  onClose,
  onSuccess,
  waiting,
  sendEvent,
}: FunnelComponentProps & {
  activeAuthType?: FUNNEL_TYPES_TYPES;
  waiting?: boolean;
}) {
  const authProps = {
    setFunnelType,
    onClose,
    onSuccess,
  };

  const alreadyTracked = useRef(false);

  useEffect(() => {
    if (sendEvent && !alreadyTracked.current) {
      sendEvent(EventTypes.viewed_registration);
      alreadyTracked.current = true;
    }
  }, []);

  return (
    <Flex width="100%" flexDirection="column" gap="24px">
      {activeAuthType === FUNNEL_TYPES.signup && (
        <>
          <SignUpModalBody
            {...authProps}
            valueProps={[]}
            welcomeText={t("components.payments.subscription.step2")}
          />
          <SignUpModalFooter {...authProps} />
        </>
      )}
      {activeAuthType === FUNNEL_TYPES.login && (
        <>
          <LoginModalBody
            {...authProps}
            loading={waiting}
            loadingText={t("components.payments.subscription.purchasing")}
          />
          <LoginModalFooter />
        </>
      )}
      {activeAuthType === FUNNEL_TYPES.signupEmailPassword && (
        <>
          <SignUpWithEmailPasswordModalBody
            {...authProps}
            loading={waiting}
            loadingText={t("components.payments.subscription.purchasing")}
          />
          <SignUpWithEmailPasswordModalFooter {...authProps} />
        </>
      )}
    </Flex>
  );
}
