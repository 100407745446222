import { PresenceMember } from "@ably/chat";
import { Image, Text, Flex, Box, Divider } from "@chakra-ui/react";
import { useIsSmallBreakpoint } from "../../../utils/useBreakpoints";
import { GCSmallLogoIcon } from "../../icons/GCSmallLogoIcon";
import { Avatar } from "../../user/Avatar";
import { UserChatProps } from "../types";
import { useMemo } from "react";

export function ChatHeader({
  members,
  title = "Chat",
  icon,
  button,
}: {
  members: Record<string, PresenceMember>;
  title?: string;
  icon?: string | JSX.Element;
  button?: JSX.Element;
}) {
  const isSmallScreen = useIsSmallBreakpoint();
  return (
    <>
      <Flex align="center" py="16px" gap="20px" px={isSmallScreen ? "16px" : 0}>
        {typeof icon === "string" ? (
          <Image
            src={icon}
            alt={title}
            height={isSmallScreen ? "56px" : "42px"}
            width={isSmallScreen ? "56px" : "42px"}
            borderRadius="30%"
          />
        ) : (
          <GCSmallLogoIcon
            color="blaze.blaze"
            boxSize={isSmallScreen ? "56px" : "42px"}
          />
        )}

        <Flex
          flex="1"
          direction={isSmallScreen ? "column" : "row"}
          align={isSmallScreen ? "flex-start" : "center"}
          justify={isSmallScreen ? "flex-start" : "space-between"}
          w="100%"
        >
          <Text
            fontWeight="700"
            fontSize={isSmallScreen ? "20px" : "32px"}
            color="dune.dune"
            fontFamily="Bricolage Grotesque"
          >
            {title}
          </Text>

          <Box ml={isSmallScreen ? "8px" : undefined}>
            <PresentMemberAvatars members={members} />
          </Box>
        </Flex>

        {button}
      </Flex>

      <Divider color="transparent.white.20" />
    </>
  );
}

function PresentMemberAvatars({
  members,
}: {
  members: Record<string, PresenceMember>;
}) {
  const memberList = Object.values(members);
  const maxVisible = 8;

  // Loop through members until we have maxVisible avatars
  // or we run out of members to loop through
  const visibleMembersArr: PresenceMember[] = useMemo(() => {
    const visibleMembers: Record<string, PresenceMember> = {};
    let i = 0;
    while (
      Object.keys(visibleMembers).length < maxVisible &&
      memberList.length > 0 &&
      memberList[i]
    ) {
      const memberData = memberList[i].data as UserChatProps;
      if (memberData.avatar) {
        visibleMembers[memberData.avatar] = memberList[i];
      } else {
        visibleMembers[
          `${window.gc.global.assets_url}/static/images/avatars/no-avatar.png`
        ] = memberList[i];
      }
      i++;
    }
    return Object.values(visibleMembers);
  }, [members]);

  const overflowCount = memberList.length - visibleMembersArr.length;

  return (
    <Flex alignItems="center">
      {visibleMembersArr.map((member, index) => {
        const { username, avatar, isGold } = (member.data ??
          {}) as UserChatProps;

        return (
          <Box
            key={member.clientId}
            ml="-12px"
            zIndex={visibleMembersArr.length - index}
          >
            <Avatar
              key={member.clientId}
              size="sm"
              username={username}
              imageSrc={avatar}
              isGold={isGold}
            />
          </Box>
        );
      })}

      {overflowCount > 0 && (
        <Box
          ml="-8px"
          boxSize="40px"
          display="flex"
          color="dune.700"
          alignItems="center"
          justifyContent="center"
          fontWeight="bold"
        >
          +{overflowCount}
        </Box>
      )}
    </Flex>
  );
}
