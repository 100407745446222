import { Box, Flex, Text } from "@chakra-ui/react";
import { Range } from "../../@types/util";
import { FreeRibbon } from "./components/FreeRibbon";
import { ContentCardActions } from "./components/ContentCardActions";
import { ContentCardFooter } from "./components/ContentCardFooter";
import { ProgressBar } from "../loading/ProgressBar";
import { LibraryStatusEntity, PPBPricingData, ReadStatus } from "../../types";
import { ReleasePriceLabel } from "./ReleasePriceLabel";

export interface CustomListCardProps {
  width: number;
  height: number;
  fullWidth?: boolean;
  onToggleFollow?: () => void;
  position?: number;
  activeBorder?: boolean;
  content: {
    entity_type: string;
    id: number;
    image_url?: string;
    title?: string;
    secondTitle?: string;
    subTitle?: string;
    description?: string;
    is_free?: boolean;
    is_favorite?: boolean;
    library_status?: LibraryStatusEntity;
    total_favorites?: number;
    total_pageviews?: number;
    relevance_match?: number;
    update_time?: string;
    total_published_releases?: number;
    total_published_pages?: number;
    status?: number;
    status_name?: "ongoing" | "updated";
    leaving_soon_date?: string;
    user_read_status?: ReadStatus | null;
    share_url?: string;
    pricingData?: PPBPricingData;
    textAboveTitle?: string;
    isLocked?: boolean;
    wasPurchased?: boolean;
    goldUnlocked?: boolean;
  };
  display: {
    include_reading_status_button?: boolean;
    include_add_library_cta?: boolean;
    include_match?: boolean;
    include_total_favorites?: boolean;
    include_total_views?: boolean;
    include_last_updated?: boolean;
    include_total_releases?: boolean;
    include_total_pages?: boolean;
    include_comic_status?: boolean;
    include_progress_meter?: boolean;
  };
}

export function CustomListCard({
  content,
  onToggleFollow,
  width,
  height,
  fullWidth,
  display,
  activeBorder,
}: CustomListCardProps) {
  return (
    <Flex
      width={fullWidth ? "calc(100% + 16px)" : width}
      justifyContent="flex-end"
    >
      <Flex
        borderLeftRadius={8}
        borderRightRadius={8}
        background="charcoal.900"
        width={fullWidth ? "calc(100% + 16px)" : width - 16}
        height={height}
        direction="row"
        border={activeBorder ? "1px solid" : ""}
        borderColor={activeBorder ? "blaze.300" : ""}
      >
        <Flex direction="column" justifyContent="center">
          <Box position="relative" left={-4}>
            {content.image_url ? (
              <Box
                background={`url(${content.image_url})`}
                backgroundPosition="top center"
                backgroundSize="100% auto"
                height="100px"
                w="65px"
                minWidth="65px"
                border="1px solid"
                borderColor="transparent.white.10"
                borderRadius={10}
              />
            ) : null}
            {content.is_free ? (
              <FreeRibbon
                color="success.500"
                position="absolute"
                top="-1px"
                left="-1px"
              />
            ) : null}
          </Box>
        </Flex>
        <Flex
          pt=".47rem"
          pb=".53rem"
          direction="column"
          justifyContent="center"
          width="100%"
          maxWidth="calc(100% - 1rem)"
          position="relative"
        >
          <Flex direction="row" width="100%" height="auto" alignItems="center">
            <Flex
              width="100%"
              direction="column"
              maxWidth="225px"
              overflow="hidden"
              pr={
                display.include_add_library_cta ||
                display.include_reading_status_button ||
                content.share_url
                  ? "24px"
                  : undefined
              }
            >
              {content.textAboveTitle && (
                <Text variant="cardSubtitle" color="charcoal.200" noOfLines={1}>
                  {content.textAboveTitle}
                </Text>
              )}
              {content.title && (
                <Text variant="cardHeader">{content.title}</Text>
              )}
              {content.secondTitle && (
                <Text variant="cardHeader">{content.secondTitle}</Text>
              )}
              {content.subTitle && (
                <Text textColor="neutral.200" variant="cardSubtitle">
                  {content.subTitle}
                </Text>
              )}

              {(!!content.isLocked ||
                (content.wasPurchased && !content.goldUnlocked)) &&
                content.pricingData && (
                  <ReleasePriceLabel pricingData={content.pricingData} />
                )}

              {content.description ? (
                <Text
                  variant="cardVitals"
                  textColor="neutral.300"
                  isTruncated
                  my={1}
                  noOfLines={2}
                  className="multiline-clamp"
                  whiteSpace="normal"
                >
                  {content.description}
                </Text>
              ) : null}
            </Flex>
            <Flex position="absolute" top="1rem" right="1rem">
              <ContentCardActions
                cacheKey={`${content.entity_type}-${content.id}`}
                showReadingStatusButton={display.include_reading_status_button}
                isFavorite={content.is_favorite}
                showLibraryCTA={display.include_add_library_cta}
                smallLibrary={true}
                isInLibrary={!!content.library_status}
                onToggleFollow={onToggleFollow}
                shareUrl={content.share_url}
                isLocked={content.isLocked}
              />
            </Flex>
          </Flex>
          <ContentCardFooter
            total_favorites={content.total_favorites}
            total_pageviews={content.total_pageviews}
            relevance_match={content.relevance_match}
            update_time={content.update_time}
            total_published_releases={content.total_published_releases}
            page_count={content.total_published_pages}
            status={content.status}
            status_name={content.status_name}
            include_match={display.include_match}
            include_total_favorites={display.include_total_favorites}
            include_total_views={display.include_total_views}
            include_last_updated={display.include_last_updated}
            include_total_releases={display.include_total_releases}
            include_total_pages={display.include_total_pages}
            include_comic_status={display.include_comic_status}
            layoutMode="list"
            leaving_soon_date={content.leaving_soon_date}
          />
          {display.include_progress_meter ? (
            <Box pr={4} pb="1px" pt={1}>
              <ProgressBar
                color1="blaze.blaze"
                color2="blaze.300"
                value={
                  content.user_read_status?.read_progress_percentage as Range<
                    0,
                    101
                  >
                }
              />
            </Box>
          ) : null}
        </Flex>
      </Flex>
    </Flex>
  );
}
