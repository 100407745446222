import { Divider, Flex } from "@chakra-ui/react";
import { PageLayoutSelector } from "./PageLayoutSelector";
import { VerticalLayoutMarginToggle } from "./VerticalLayoutMarginToggle";
import { PageSizingSelector } from "./PageSizingSelector";
import { P2PReadingSettings } from "./P2PReadingSettings";
import { SlideInHeader } from "../../../../components/slideInMenu/components/SlideInHeader";
import { SLIDE_IN_MENUS } from "../../../root/constants";

export function SettingsMenu({ onCloseMenu }: { onCloseMenu?: () => void }) {
  return (
    <>
      <SlideInHeader
        openMenu={SLIDE_IN_MENUS.settings}
        onCloseMenu={onCloseMenu}
      />
      <Flex
        p="24px"
        gap="24px"
        width="100%"
        height="100%"
        flexDirection="column"
        overflowY="auto"
        paddingTop="96px"
      >
        <PageLayoutSelector />
        <VerticalLayoutMarginToggle />
        <Divider />
        <PageSizingSelector />
        <Divider />
        <P2PReadingSettings />
      </Flex>
    </>
  );
}
