import { RefObject, useCallback, useEffect, useRef, useState } from "react";

export function useTrackScrolling<T extends RefObject<HTMLElement>>(ref: T) {
  const [isScrolling, setIsScrolling] = useState(false);
  const scrollTimer = useRef<NodeJS.Timeout>();
  const [lastScrollPosition, setLastScrollPosition] = useState(-1);

  // Track last scroll position changes and reset "stop" timer
  useEffect(() => {
    clearTimeout(scrollTimer.current);
    scrollTimer.current = setTimeout(() => {
      handleStopScrolling();
    }, 100);
  }, [lastScrollPosition]);

  const handleIsScrolling = useCallback(
    (e: Event) => {
      setIsScrolling(true);
      // Track scrolling
      setLastScrollPosition(
        (e.target as HTMLElement).scrollTop ||
          (e.target as HTMLElement).scrollLeft,
      );
    },
    [setIsScrolling, setLastScrollPosition],
  );

  const handleStopScrolling = useCallback(() => {
    setIsScrolling(false);
    // Clear scroll timer
    clearTimeout(scrollTimer.current);
  }, [setIsScrolling]);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    ref.current.addEventListener("scroll", handleIsScrolling, {
      passive: true,
    });
    ref.current.addEventListener("scrollend", handleStopScrolling, {
      passive: true,
    });

    return () => {
      if (!ref.current) {
        return;
      }
      ref.current.removeEventListener("scroll", handleIsScrolling);
      ref.current.removeEventListener("scrollend", handleStopScrolling);
    };
  }, [ref]);

  return isScrolling;
}
