import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { Text, Box, Flex } from "@chakra-ui/react";
import { PurchaseButton } from "./PurchaseButton";
import { t } from "i18next";
import { memo, SyntheticEvent, useEffect, useState } from "react";
import { theme } from "../../../theme/theme";
import { SavedPaymentMethods } from "./SavedPaymentMethods";
import { RadioOnIcon } from "../../icons/RadioOnIcon";
import { stripePromise } from "../../../App";
import { OwnerPaymentServiceTokenTypes } from "../types";

export type StripePaymentConfigTypes = {
  ownerPaymentServiceTokenId?: string;
  stripeToken?: string;
};

interface StripePaymentProps {
  submitButtonText?: string;
  completePurchase: (paymentConfig: StripePaymentConfigTypes) => void;
  waiting?: boolean;
  failedCard?: OwnerPaymentServiceTokenTypes;
  responseError?: string;
  isDisabled?: boolean;
  isModal?: boolean;
  isGiftCard?: boolean;
  isSmall?: boolean;
}

export const StripePayment = memo(
  ({
    submitButtonText,
    completePurchase,
    waiting,
    responseError,
    failedCard,
    isDisabled = false,
    isModal = false,
    isSmall = false,
  }: StripePaymentProps) => {
    return (
      <Elements
        stripe={stripePromise}
        options={{
          fonts: [
            {
              cssSrc:
                "https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap",
            },
          ],
        }}
      >
        <StripeCheckoutForm
          waiting={waiting}
          submitButtonText={submitButtonText}
          completePurchase={completePurchase}
          failedCard={failedCard}
          responseError={responseError}
          isDisabled={isDisabled}
          isModal={isModal}
          isSmall={isSmall}
        />
      </Elements>
    );
  },
);

export function StripeCheckoutForm({
  submitButtonText,
  completePurchase,
  waiting,
  responseError,
  failedCard,
  isDisabled,
  isModal,
  isGiftCard,
  isSmall,
}: StripePaymentProps) {
  const [ownerPaymentServiceTokenId, setOwnerPaymentServiceTokenId] =
    useState<string>();

  useEffect(() => {
    if (failedCard) {
      setOwnerPaymentServiceTokenId(failedCard.id.toString());
    }
  }, [failedCard]);

  const { loading, setLoading, error, setError, handleSubmit } =
    useStripePayment(completePurchase);

  const [autoFocus, setAutoFocus] = useState(false);
  useEffect(() => {
    if (ownerPaymentServiceTokenId && ownerPaymentServiceTokenId !== "0") {
      setLoading(false);
      setAutoFocus(true);
    }
  }, [ownerPaymentServiceTokenId]);

  useEffect(() => {
    setError(responseError);
  }, [responseError]);

  // Extract themes so I can pass them into the stipe config
  // which doesn't know our themes
  const errorColor = theme.colors.error["300"];
  const textColor = theme.colors.dune["600"];
  return (
    <form
      style={{
        width: "100%",
        borderRadius: "16px",
        marginBottom: isModal ? "84px" : undefined,
      }}
      onSubmit={(e) => {
        if (ownerPaymentServiceTokenId === "0") {
          handleSubmit(e);
        } else {
          e.preventDefault();
          completePurchase({ ownerPaymentServiceTokenId });
        }
      }}
    >
      <Flex flexDirection="column" gap="16px" pb={isSmall ? "16px" : "32px"}>
        <SavedPaymentMethods
          setOwnerPaymentServiceTokenId={setOwnerPaymentServiceTokenId}
          ownerPaymentServiceTokenId={ownerPaymentServiceTokenId}
          onMethodChange={() => setError(undefined)}
          isSmall={isSmall}
        />
        {ownerPaymentServiceTokenId === "0" && (
          <Box
            display="flex"
            alignItems="center"
            height={isSmall ? "40px" : undefined}
            paddingY={!isSmall ? "12px" : undefined}
            paddingX="16px"
            bg="transparent.white.10"
            borderRadius="16px"
            border="1px solid"
            boxShadow={
              error
                ? "0px 0px 5px 0px var(--chakra-colors-error-300)"
                : undefined
            }
            borderColor={error ? "error.300" : "transparent"}
          >
            <Box
              alignItems="center"
              height={!isSmall ? "24px" : undefined}
              width="36px"
              display="inline-block"
            >
              <RadioOnIcon height="24px" width="24px" color="blaze.blaze" />
            </Box>
            <Box
              display="inline-block"
              width="calc(100% - 36px)"
              height={isSmall ? undefined : "100%"}
            >
              <CardElement
                onChange={(e) =>
                  e.error ? setError(e.error.message) : setError(undefined)
                }
                onReady={(element) => {
                  setLoading(false);
                  if (autoFocus) element.focus();
                }}
                options={{
                  disableLink: true,
                  style: {
                    base: {
                      fontSize: "14px",
                      fontWeight: "500",
                      fontFamily: "Roboto",
                      color: textColor,
                      "::placeholder": {
                        color: textColor,
                      },
                    },
                    invalid: {
                      color: errorColor,
                      iconColor: errorColor,
                    },
                  },
                }}
              />
            </Box>
          </Box>
        )}
      </Flex>
      <Box width="100%" height={error ? "24px" : "0px"} pl="8px">
        {error && (
          <Text
            color="error.300"
            fontFamily="Roboto"
            fontSize="14px"
            lineHeight="130%"
          >
            {error}
          </Text>
        )}
      </Box>
      {isModal ? (
        <Box
          position="fixed"
          p="24px"
          width="100%"
          bottom="0"
          left="0"
          borderTop="1px solid"
          borderColor="transparent.white.10"
          bg="charcoal.charcoal"
          borderBottomRadius="16px"
        >
          <PurchaseButton
            isDisabled={isDisabled || waiting || loading}
            text={
              submitButtonText ||
              t(
                isGiftCard
                  ? "components.payments.subscription.confirmGiftCardPayment"
                  : "components.payments.subscription.confirmYourPayment",
              )
            }
            loading={waiting || loading}
          />
        </Box>
      ) : (
        <PurchaseButton
          isDisabled={isDisabled || waiting || loading}
          text={
            submitButtonText ||
            t(
              isGiftCard
                ? "components.payments.subscription.confirmGiftCardPayment"
                : "components.payments.subscription.confirmYourPayment",
            )
          }
          loading={waiting || loading}
        />
      )}
    </form>
  );
}

function useStripePayment(
  completePurchase: ({
    stripeToken,
    ownerPaymentServiceTokenId,
  }: StripePaymentConfigTypes) => void,
) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();

  const stripe = useStripe();
  const elements = useElements();
  const handleSubmit = async (event: SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!stripe || !elements) return;

    setLoading(true);
    const cardElement = elements.getElement(CardElement);
    if (!cardElement) return;

    const { error, token } = await stripe.createToken(cardElement);

    if (error) {
      setError(error.message);
    } else {
      //cardElement.clear();
      completePurchase({ stripeToken: token.id });
    }
  };

  useEffect(() => {
    if (error) {
      setLoading(false);
    }
  }, [error]);

  useEffect(() => {
    if (loading) {
      setError(undefined);
    } else {
      setLoading(false);
    }
  }, [loading]);

  return {
    loading,
    setLoading,
    error,
    setError,
    handleSubmit,
  };
}
