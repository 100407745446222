import { useTranslation } from "react-i18next";
import { PPBPricingData } from "../../types";
import { Box, Flex, Text } from "@chakra-ui/react";
import { useAccountData } from "../../hooks/useAccountData";
import { ShoppingPurchasedIcon } from "../icons/ShoppingPurchasedIcon";

export interface ReleasePriceLabelProps {
  pricingData: PPBPricingData;
  compact?: boolean;
}

export function ReleasePriceLabel({
  pricingData,
  compact = false,
}: ReleasePriceLabelProps) {
  const accountData = useAccountData();
  let userIsGold = accountData?.user?.gold;

  const wasPurchased = !!pricingData.release_user_purchase;

  if (pricingData.is_free || (userIsGold && !pricingData.premium_only)) return;

  if (wasPurchased) {
    return (
      <Flex dir="row">
        <ShoppingPurchasedIcon color="blaze.600" />
        <Box width="4px" />
        <Text variant="unlockableCard" color="neutral.200">
          You purchased this
        </Text>
      </Flex>
    );
  }

  if (pricingData.ppb_gold_price_point || pricingData.ppb_price_point) {
    let pricesAreDifferent =
      pricingData.ppb_gold_price_point &&
      pricingData.ppb_price_point &&
      pricingData.ppb_gold_price_point != pricingData.ppb_price_point;

    let ppb_gold_price_point = !!pricingData.ppb_gold_price_point
      ? pricingData.ppb_gold_price_point / 100
      : undefined;

    let ppb_price_point = !!pricingData.ppb_price_point
      ? pricingData.ppb_price_point / 100
      : undefined;

    if (pricingData.premium_only) {
      if (!pricesAreDifferent) {
        return (
          <Text variant="unlockableCard" color="neutral.200">
            {" "}
            ${ppb_price_point}
          </Text>
        );
      }

      return (
        <Flex direction="row">
          <Text
            variant="unlockableCard"
            color={!userIsGold ? "charcoal.200" : "blaze.blaze"}
          >
            ${userIsGold ? ppb_gold_price_point : ppb_price_point}
            {userIsGold ? "" : " • "}
          </Text>
          <Text
            variant="unlockableCard"
            paddingLeft={userIsGold ? "4px" : "2px"}
            color={userIsGold ? "charcoal.200" : "blaze.blaze"}
            textDecorationLine={userIsGold ? "line-through" : undefined}
          >
            ${userIsGold ? ppb_price_point : ppb_gold_price_point}
            {userIsGold ? "" : compact ? " Gold" : " with Gold"}
          </Text>
        </Flex>
      );
    } else {
      return (
        <Flex direction="row" gap="2px">
          <Text variant="unlockableCard" color="charcoal.200">
            ${ppb_price_point} •{"  "}
          </Text>
          <UnlockWithGoldLabel />
        </Flex>
      );
    }
  } else if (!userIsGold) {
    return <UnlockWithGoldLabel />;
  }
}

function UnlockWithGoldLabel() {
  const { t } = useTranslation();

  return (
    <Text variant="unlockableCard">
      {t("components.cards.unlockReleaseCard.readWithGold")}
    </Text>
  );
}
