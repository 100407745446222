import { Flex, Text } from "@chakra-ui/react";
import { NewsCardImage } from "./NewsCardImage";
import { t } from "i18next";
import { Article } from "../../../types";
import { Link } from "react-router-dom";
import { useIsSmallBreakpoint } from "../../../utils/useBreakpoints";

export function TallNewsCard({
  preview_image_url,
  url,
  title,
  category,
  author: { name },
  largeVariant = false,
}: Article & { largeVariant?: boolean }) {
  const isSmallBreakpoint = useIsSmallBreakpoint();
  return (
    <Flex
      width="100"
      display="flex"
      flexDirection="column"
      gap={isSmallBreakpoint ? (largeVariant ? "16px" : "8px") : "24px"}
      height="min-content"
    >
      <Link to={new URL(url).pathname}>
        <NewsCardImage
          imageProps={{
            src: preview_image_url,
            title: title,
            flexShrink: 0,
            objectFit: "contain",
          }}
        />
      </Link>
      <Flex direction="column" gap="8px" justifyContent="center" flex={1}>
        {category && (
          <Link to={`/news/${category.display_name}`}>
            <Text variant="newsCardVital" color="blaze.blaze">
              {category.display_name}
            </Text>
          </Link>
        )}
        <Link to={new URL(url).pathname}>
          <Text
            variant="newsCardTitle"
            fontSize={
              isSmallBreakpoint ? (largeVariant ? "20px" : "14px") : "24px"
            }
          >
            {title}
          </Text>
        </Link>
        <Link to={`/u/${name}`}>
          <Text variant="newsCardVital">
            {t("components.newsCards.by", { name })}
          </Text>
        </Link>
      </Flex>
    </Flex>
  );
}
