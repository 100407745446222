import { Article, LayoutStyle } from "../../types";
import { WideNewsCard } from "./components/WideNewsCard";
import { TallNewsCard } from "./components/TallNewsCard";
import { useIsSmallBreakpoint } from "../../utils/useBreakpoints";

export function NewsCard({
  content,
  size,
}: {
  content: Article;
  size: LayoutStyle["size"];
}) {
  const isSmallBreakpoint = useIsSmallBreakpoint();
  const cardMap: Record<string, JSX.Element> = {
    xl: isSmallBreakpoint ? (
      <TallNewsCard {...content} largeVariant />
    ) : (
      <WideNewsCard {...content} largeVariant />
    ),
    l: <TallNewsCard {...content} largeVariant />,
    m: <TallNewsCard {...content} />,
    s: <WideNewsCard {...content} />,
  };

  return cardMap[size];
}
