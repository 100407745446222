import { createContext, useContext, useEffect, useState } from "react";
import { FunnelNavigationContext } from "../../funnel/types";
import { FUNNEL_TYPES } from "../../funnel/utils/contants";

export type PaywallType =
  | FUNNEL_TYPES.subscription
  | FUNNEL_TYPES.purchaseRelease;
export type PaywalLConfigType = {
  type: PaywallType;
  navigationContext: FunnelNavigationContext | undefined;
};

const PaywallContext = createContext<
  | {
      setPaywallConfig: (paywallConfig?: PaywalLConfigType) => void;
      paywallConfig?: PaywalLConfigType;
    }
  | undefined
>(undefined);

export default function PaywallProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [paywallConfig, setPaywallConfig] = useState<
    PaywalLConfigType | undefined
  >(undefined);

  useEffect(() => {
    setPaywallConfig(undefined);
  }, []);

  return (
    <PaywallContext.Provider value={{ paywallConfig, setPaywallConfig }}>
      {children}
    </PaywallContext.Provider>
  );
}

export const usePaywall = () => useContext(PaywallContext);
