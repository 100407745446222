import { apiClient } from "../../../services/axiosInstance";
import { Article, ServerListResponse, ServerResponse } from "../../../types";

export function getNewsDetail(articleId: string) {
  return apiClient.get<ServerResponse<Article>>(`/v1/news/${articleId}`);
}

export const GET_NEWS_DETAIL_QUERY_KEY = ["news-detail"];

export function getRelatedNews(articleId: number) {
  return apiClient.get<ServerListResponse<Article>>(
    `/v1/news/${articleId}/related`,
  );
}

export const GET_RELATED_NEWS_QUERY_KEY = ["news-related"];

export function postToggleReaction({
  articleId,
  reactionTypeId,
}: {
  articleId: number;
  reactionTypeId: number;
}) {
  return apiClient.post(`/v1/news/${articleId}/toggle-reaction`, {
    meta: [],
    payload: {
      reaction_type_id: reactionTypeId,
    },
  });
}

export function postComment({
  articleId,
  parentId,
  content,
}: {
  articleId: number;
  parentId?: number;
  content: string;
}) {
  return apiClient.post(`/v1/news/${articleId}/post-comment`, {
    meta: {},
    payload: {
      body: content,
      parentId: parentId,
    },
  });
}

export function getNews({
  categoryId,
  pageParam,
}: {
  categoryId?: number;
  pageParam?: number;
}) {
  const queryParams = new URLSearchParams();
  queryParams.set("p", "" + pageParam);
  if (categoryId) queryParams.set("category", "" + categoryId);

  return apiClient.get<ServerListResponse<Article>>(
    `/v1/news?${queryParams.toString()}`,
  );
}
