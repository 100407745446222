import { useQuery } from "@tanstack/react-query";
import {
  GET_SAVED_PAYMENT_METHODS_QUERY_KEY,
  getSavedPaymentMethods,
} from "../api/paymentApi";
import {
  Flex,
  Stack,
  Box,
  useRadioGroup,
  UseRadioProps,
  Image,
  useRadio,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { t } from "i18next";
import { RadioOnIcon } from "../../icons/RadioOnIcon";
import { RadioOffIcon } from "../../icons/RadioOffIcon";
import { useUserData } from "../../../hooks/useUserData";

export function SavedPaymentMethods({
  ownerPaymentServiceTokenId,
  setOwnerPaymentServiceTokenId,
  onMethodChange,
  waiting = false,
  isSmall = false,
}: {
  ownerPaymentServiceTokenId?: string;
  setOwnerPaymentServiceTokenId: (value: string) => void;
  onMethodChange?: () => void;
  waiting?: boolean;
  isSmall?: boolean;
}) {
  const { userData } = useUserData();

  const { data, isLoading } = useQuery({
    enabled: !!userData || waiting,
    queryKey: GET_SAVED_PAYMENT_METHODS_QUERY_KEY,
    queryFn: getSavedPaymentMethods,
    refetchOnWindowFocus: false,
  });

  const savedPaymentMethods = data?.data.payload.results;
  useEffect(() => {
    if (ownerPaymentServiceTokenId) return;

    const primaryCard = savedPaymentMethods?.filter(
      (paymentMethod) => paymentMethod.is_primary,
    )?.[0];
    if (primaryCard) {
      setOwnerPaymentServiceTokenId(primaryCard.id.toString());
    } else if (savedPaymentMethods?.length) {
      setOwnerPaymentServiceTokenId(savedPaymentMethods[0].id.toString());
    } else {
      setOwnerPaymentServiceTokenId("0");
    }
  }, [data]);

  const { getRadioProps } = useRadioGroup({
    value: ownerPaymentServiceTokenId,
    onChange: (value) => {
      setOwnerPaymentServiceTokenId(value);
      onMethodChange?.();
    },
  });

  if (!savedPaymentMethods?.length || isLoading) {
    return null;
  }

  return (
    <Stack gap="16px">
      {savedPaymentMethods?.map((paymentMethod) => (
        <PaymentRadio
          isSmall={isSmall}
          key={paymentMethod.id}
          {...getRadioProps({ value: paymentMethod.id.toString() })}
        >
          <Flex justifyContent="space-between" width="100%">
            <Flex gap="12px" alignItems="center">
              {PaymentCardIcon(paymentMethod.brand)}
              <Flex align="center">
                {paymentMethod.brand} ****{paymentMethod.last4}
              </Flex>
            </Flex>
            <Flex alignItems="center">
              {t("components.payments.payments.expires")}:{" "}
              {paymentMethod.exp_month.toString().padStart(2, "0")}/
              {paymentMethod.exp_year}
            </Flex>
          </Flex>
        </PaymentRadio>
      ))}
      {ownerPaymentServiceTokenId !== "0" && (
        <PaymentRadio {...getRadioProps({ value: "0" })} isSmall={isSmall}>
          <Flex align="center" height="24px">
            {t("components.payments.payments.newCardNumber")}
          </Flex>
        </PaymentRadio>
      )}
    </Stack>
  );
}

interface CustomRadioProps extends UseRadioProps {
  children: React.ReactNode;
  useCheck?: boolean;
  isSmall?: boolean;
}
export function PaymentRadio(props: CustomRadioProps) {
  const { state, getInputProps } = useRadio(props);
  const input = getInputProps();

  return (
    <Box
      layerStyle="filterList"
      cursor="pointer"
      height={props.isSmall ? "40px" : undefined}
      paddingY={!props.isSmall ? "12px" : undefined}
      paddingX="16px"
      bg="transparent.white.10"
      borderRadius="16px"
      border="1px solid transparent"
      as="label"
      justifyContent="flex-start"
      fontSize="14px"
      fontWeight="500"
      fontFamily="Roboto"
      color="dune.600"
      gap="12px"
      alignItems="center"
    >
      <input {...input} hidden />
      <Flex height="100%">
        {state.isChecked ? (
          <RadioOnIcon
            boxSize="24px"
            pointerEvents="none"
            color="blaze.blaze"
          />
        ) : (
          <RadioOffIcon
            boxSize="24px"
            pointerEvents="none"
            color="transparent.white.40"
          />
        )}
      </Flex>
      {props.children}
    </Box>
  );
}

export function PaymentCardIcon(brand: string) {
  const paymentCardIcons = {
    Visa: "https://js.stripe.com/v3/fingerprinted/img/visa-729c05c240c4bdb47b03ac81d9945bfe.svg",
    MasterCard:
      "https://js.stripe.com/v3/fingerprinted/img/mastercard-4d8844094130711885b5e41b28c9848f.svg",
    "American Express":
      "https://js.stripe.com/v3/fingerprinted/img/amex-a49b82f46c5cd6a96a6e418a6ca1717c.svg",
    Discover:
      "https://js.stripe.com/v3/fingerprinted/img/discover-ac52cd46f89fa40a29a0bfb954e33173.svg",
    "Diners Club":
      "https://js.stripe.com/v3/fingerprinted/img/diners-fbcbd3360f8e3f629cdaa80e93abdb8b.svg",
    UnionPay:
      "https://js.stripe.com/v3/fingerprinted/img/unionpay-8a10aefc7295216c338ba4e1224627a1.svg",
    JCB: "https://js.stripe.com/v3/fingerprinted/img/jcb-271fd06e6e7a2c52692ffa91a95fb64f.svg",
  };

  return (
    <Image
      width="1.5em"
      height="18px"
      alt={brand}
      src={paymentCardIcons[brand as keyof typeof paymentCardIcons]}
    />
  );
}
