import { SendMessageParams } from "@ably/chat";
import { FormControl, Button } from "@chakra-ui/react";
import { useState, useEffect, FormEvent } from "react";
import { useGetNavigationContext } from "../../../hooks/useGetNavigationContext";
import { useUserData } from "../../../hooks/useUserData";
import { setFunnelModalConfig } from "../../funnel/FunnelModal";
import { FunnelContextArea } from "../../funnel/types";
import { FUNNEL_TYPES } from "../../funnel/utils/contants";
import { SendIcon } from "../../icons/SendIcon";
import { Avatar } from "../../user/Avatar";
import { TextField } from "../../fields/TextField";
import { useIsSmallBreakpoint } from "../../../utils/useBreakpoints";

export function ChatSubmitForm({
  send,
  disabled = false,
}: {
  send: (params: SendMessageParams) => void;
  disabled?: boolean;
}) {
  const [message, setMessage] = useState("");
  const { userData } = useUserData();
  const isSmallScreen = useIsSmallBreakpoint();

  const sendMessage = () => {
    send({
      text: message,
      metadata: {
        username: userData?.name,
        isGold: userData?.gold,
        avatar: userData?.avatar_tiny_url,
      },
    });
    setMessage("");
  };

  const [pending, setPending] = useState(false);
  useEffect(() => {
    if (userData && pending) {
      setPending(false);
      sendMessage();
    }
  }, [userData]);

  const navigationContext = useGetNavigationContext({
    contextArea: FunnelContextArea.globalChat,
  });

  const handleSubmit = (e: FormEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (!userData) {
      setFunnelModalConfig({
        type: FUNNEL_TYPES.signup,
        options: {
          modalContext: FunnelContextArea.globalChat,
          navigationContext,
        },
      });
      setPending(true);
    } else if (message.trim()) {
      sendMessage();
    }
  };

  const sendButtonHoverColor =
    message.length > 0 ? "success.800" : "transparent.white.20";

  return (
    <FormControl
      as="form"
      label="Chat Input Form"
      onSubmit={handleSubmit}
      position="relative"
      px={isSmallScreen ? "16px" : 0}
      pb={isSmallScreen ? "16px" : 0}
      isDisabled={disabled}
    >
      <TextField
        type="text"
        placeholder=""
        height={isSmallScreen ? "56px" : "80px"}
        variant="outline"
        borderRadius="24px"
        inputPadding="68px"
        onChange={(e) => setMessage(e)}
        autoFocus
        autoComplete="off"
        value={message}
        prefix={
          <Avatar
            size={isSmallScreen ? "md" : "lg"}
            paddingLeft="32px"
            isGold={userData?.gold}
            username={userData?.name}
            imageSrc={userData?.avatar_tiny_url}
          />
        }
        suffix={
          <Button
            mr="32px"
            type="submit"
            borderRadius={isSmallScreen ? "12px" : "20px"}
            boxSize={isSmallScreen ? "32px" : "48px"}
            aria-label="send message"
            background={
              message.length > 0 ? "success.700" : "transparent.white.10"
            }
            minW={isSmallScreen ? "32px" : undefined}
            padding={isSmallScreen ? "0px" : undefined}
            _hover={{
              background: sendButtonHoverColor,
            }}
          >
            <SendIcon boxSize={isSmallScreen ? "20px" : "24px"} />
          </Button>
        }
        background="transparent.white.05"
        _hover={{}}
        _focusVisible={{}}
        isDisabled={disabled}
      />
    </FormControl>
  );
}
