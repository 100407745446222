import { BoxProps, Flex } from "@chakra-ui/react";
import { BouncyImage } from "./BouncyImage";
import { DoubleBouncyImage } from "./DoubleBouncyImage";
import { useHorizontalReader } from "../../hooks/useHorizontalReader";
import { usePreloadPages } from "../../hooks/usePreloadPages";
import { useTrackPageView } from "../../hooks/useTrackPageView";
import { EndInterstitial } from "../interstitals/EndInterstitial";
import { useP2PSettings } from "../../hooks/useP2PSettings";
import { useIsOverlayOpen } from "../../hooks/hookstate/useIsOverlayOpen";
import { ImmutableArray, useHookstate } from "@hookstate/core";
import { Page } from "../../../../types";
import { useP2PUrl } from "../../hooks/useP2PUrl";
import { P2PReader } from "./P2PReader";
import { useEffect, useRef } from "react";
import { rootComponentsStore } from "../../../../components/layouts/RootLayout";
import { FUNNEL_TYPES } from "../../../../components/funnel/utils/contants";
import { setFunnelModalConfig } from "../../../../components/funnel/FunnelModal";
import { useUserData } from "../../../../hooks/useUserData";
import { useIsSmallBreakpoint } from "../../../../utils/useBreakpoints";
import { useNavigationActions } from "../../hooks/useNavigationActions";
import { useReleaseDataQueryKey } from "../../hooks/useReleaseDataQueryKey";
import { useInvalidateQueries } from "../../../../services/axiosInstance";
import { FunnelContextArea } from "../../../../components/funnel/types";
import { useGetNavigationContext } from "../../../../hooks/useGetNavigationContext";
import isTouchDevice from "../../../../utils/isTouchDevice";
import { useGestureControls } from "../../hooks/useGestureControls";
import { useNavigate } from "react-router";
import { useReleaseData } from "../../hooks/hookstate/useReleaseData";
import { useAccountData } from "../../../../hooks/useAccountData";

interface HorizontalReaderProps {
  readingDirection: string;
  releasePagesData?: ImmutableArray<Page>;
}
export function HorizontalReader({
  readingDirection,
  releasePagesData,
}: HorizontalReaderProps) {
  useTrackPageView();
  usePreloadPages(releasePagesData);
  useP2PUrl();

  const { toggleIsOverlayOpen } = useIsOverlayOpen();

  const {
    activePage,
    showEndInterstitial,
    releasePageData,
    doubleReleasePageData,
    directionMoved,
    fullHeightReader,
    readerHorizontalScroll,
    showPaywall,
  } = useHorizontalReader(readingDirection);

  const { userData } = useUserData();
  const accountData = useAccountData();
  const isSmallBreakpoint = useIsSmallBreakpoint();
  const navigationContext = useGetNavigationContext({
    contextArea: FunnelContextArea.reader,
  });

  const openModal = useHookstate(rootComponentsStore.funnelModal.type).get();
  const navigationContextArea = useHookstate(
    rootComponentsStore.funnelModal.options,
  ).get()?.navigationContext?.contextArea;
  const { goPrevious } = useNavigationActions();
  const releaseQueryKey = useReleaseDataQueryKey();
  const invalidateReleaseData = useInvalidateQueries(
    releaseQueryKey as string[],
  );
  const { releaseData } = useReleaseData();

  const navigate = useNavigate();
  const locationHistory = useHookstate(
    rootComponentsStore.locationHistory,
  ).get();
  const lastActivePage = useRef<number>(activePage);
  useEffect(() => {
    const isPpbEnabled = accountData?.features["ppb_pre_paywall"] ?? false;
    if (
      (!userData?.gold ||
        (!releaseData?.release_user_purchase && isPpbEnabled)) &&
      showPaywall &&
      (!openModal || lastActivePage.current !== activePage)
    ) {
      lastActivePage.current = activePage;
      const onClose = () => {
        const isFirstPage = activePage === 0;
        if (isFirstPage) {
          locationHistory &&
          locationHistory.pathname !== window.location.pathname
            ? navigate(-1)
            : navigate("/");
        } else {
          isSmallBreakpoint ? goPrevious() : undefined;
        }
      };

      setFunnelModalConfig({
        type: isPpbEnabled
          ? FUNNEL_TYPES.prePaywall
          : FUNNEL_TYPES.subscription,
        options: {
          purchaseRelease: isPpbEnabled ? releaseData : undefined,
          locked: true,
          skipAuthentication: true,
          onClose,
          onAuthenticated: invalidateReleaseData,
          navigationContext: navigationContext,
        },
      });

      () => setFunnelModalConfig();
    } else if (
      !showPaywall &&
      (openModal === FUNNEL_TYPES.subscription ||
        openModal == FUNNEL_TYPES.prePaywall) &&
      navigationContextArea === FunnelContextArea.reader
    ) {
      setFunnelModalConfig();
    }
  }, [
    showPaywall,
    openModal,
    userData,
    isSmallBreakpoint,
    activePage,
    releaseData,
  ]);

  useEffect(() => {
    window.document.getElementById("readerContainer")?.scrollTo(0, 0);
  }, [releasePageData]);

  const { x, opacity } = useGestureControls(directionMoved, releasePageData);

  const { p2pEnabledAndAugmented } = useP2PSettings();
  const isTouch = isTouchDevice();
  const horizontalReaderProps: BoxProps = p2pEnabledAndAugmented
    ? {
        height: "fit-content",
      }
    : {
        height: "fit-content",
        flexDirection: "column",
        style: {
          touchAction: isTouch ? "auto" : "none",
        },
      };

  const swipeProps = {
    opacity,
    x,
  };
  return (
    <Flex
      id="horizontalReader"
      display="flex"
      alignItems="center"
      overflow="hidden"
      margin="auto"
      onClick={toggleIsOverlayOpen}
      {...horizontalReaderProps}
    >
      {p2pEnabledAndAugmented ? (
        <P2PReader releasePageData={releasePageData} swipeProps={swipeProps} />
      ) : !doubleReleasePageData ? (
        <BouncyImage
          fullHeightReader={fullHeightReader}
          releasePageData={releasePageData}
          swipeProps={swipeProps}
        />
      ) : (
        <DoubleBouncyImage
          readingDirection={readingDirection}
          fullHeightReader={readerHorizontalScroll}
          releasePageData={releasePageData}
          doubleReleasePageData={doubleReleasePageData}
          swipeProps={swipeProps}
        />
      )}
      {showEndInterstitial && <EndInterstitial />}
    </Flex>
  );
}
