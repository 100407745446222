import { Flex } from "@chakra-ui/react";
import { useFunnel } from "../../../components/funnel/hooks/useFunnel";
import { useState } from "react";
import {
  FUNNEL_TYPES,
  FUNNEL_TYPES_TYPES,
} from "../../../components/funnel/utils/contants";
import { FunnelContextArea } from "../../../components/funnel/types";
import { useGetNavigationContext } from "../../../hooks/useGetNavigationContext";
import { useReleaseData } from "../hooks/hookstate/useReleaseData";
import { useAccountData } from "../../../hooks/useAccountData";

interface ReaderPaywallProps {}
export function ReaderPaywall({}: ReaderPaywallProps) {
  const accountData = useAccountData();
  const isPpbEnabled = accountData?.features["ppb_pre_paywall"] ?? false;
  const [funnelType, setFunnelType] = useState<FUNNEL_TYPES_TYPES | undefined>(
    isPpbEnabled ? FUNNEL_TYPES.prePaywall : FUNNEL_TYPES.subscription,
  );

  const navigationContext = useGetNavigationContext({
    contextArea: FunnelContextArea.reader,
  });
  const { releaseData } = useReleaseData();

  const { funnelBody, funnelFooter } = useFunnel(
    () => setFunnelType(undefined),
    (funnelConfig) => setFunnelType(funnelConfig.type),
    funnelType,
    {
      skipAuthentication: true,
      navigationContext,
      purchaseRelease: isPpbEnabled ? releaseData : undefined,
    },
  );

  return (
    <Flex
      height="100%"
      bg="reader.slate.600"
      py="24px"
      justifyContent="center"
      borderRadius="24px"
      onClick={(e) => e.stopPropagation()}
      filter="drop-shadow(rgba(0, 0, 0, 0.04)"
    >
      <Flex
        maxWidth="100vw"
        px="24px"
        overflowY="auto"
        flexDirection="column"
        gap="24px"
        alignItems="center"
        borderRadius="16px"
      >
        {funnelBody}
        {funnelFooter}
      </Flex>
    </Flex>
  );
}
