import { apiClient } from "../../../services/axiosInstance";
import {
  Section,
  ServerListResponse,
  ServerSingleResponse,
} from "../../../types";

export function getSection(slug: string) {
  return apiClient.get<ServerSingleResponse<Section>>(
    `/v1/cms-sections/${slug}`,
  );
}
export function getSections(
  activeChannel: string,
  pageParam?: number,
  signal?: AbortSignal,
) {
  return apiClient.get<ServerListResponse<Section>>(
    `/v1/cms/${activeChannel}/sections${pageParam ? "?p=" + pageParam : ""}`,
    { signal },
  );
}

export const GET_SECTIONS_QUERY_KEY = "sections";
