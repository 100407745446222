import { Box, Flex, HStack, Text, Tooltip } from "@chakra-ui/react";
import { useLocation, useMatch, useParams } from "react-router";
import { PreloadLink } from "./PreloadLink";

interface SideBarItemProps {
  icon: JSX.Element;
  buttonElement?: JSX.Element;
  to: string;
  text?: string;
  external?: boolean;
  collapsed?: boolean;
  disabled?: boolean;
}

export function SideBarItem({
  icon,
  to,
  text,
  external,
  collapsed,
  buttonElement,
  disabled = false,
}: SideBarItemProps) {
  const { channel } = useParams();

  const location = useLocation();

  const isCreatorsPage = location.pathname.startsWith("/creators");
  const matches = to !== "/" && location.pathname.startsWith(to);

  const itemActive =
    useMatch(to) ||
    matches ||
    (to.includes("/channel") && channel && to.includes(channel)) ||
    (to === "/" && channel && channel === "gc") ||
    (to === "/browse" && isCreatorsPage) ||
    (to === "/chat" && isCreatorsPage);

  return (
    <PreloadLink
      to={to}
      name={text}
      reloadDocument={external}
      style={{
        width: "100%",
        cursor: disabled ? "default" : "pointer",
        opacity: disabled ? 0.7 : 1,
      }}
      aria-disabled={disabled}
    >
      <Tooltip
        variant="navbar"
        label={text}
        aria-label={`Navigate to ${text} page`}
        placement="right"
        isDisabled={!collapsed || !!itemActive}
        gutter={4}
      >
        <HStack alignItems="center" w="full" role="group">
          <Box
            display="flex"
            w={"100%"}
            alignItems="center"
            transition="all .1s linear"
            boxShadow={
              itemActive ? "0 0 0 2px var(--chakra-colors-white-10)" : undefined
            }
            borderRadius="16px"
            background={itemActive ? "transparent.white.10" : undefined}
            _groupHover={{
              color: itemActive ? undefined : "dune.700",
              background: itemActive ? undefined : "transparent.white.05",
            }}
          >
            <Flex
              w="40px"
              h="40px"
              borderRadius="16px"
              justifyContent="center"
              alignItems="center"
              transition="inherit"
              color={itemActive ? "dune.100" : "slate.950"}
              _groupHover={{
                color: itemActive ? undefined : "dune.700",
              }}
            >
              {icon}
            </Flex>
            {!collapsed ? (
              <Flex
                flexGrow={1}
                justifyContent="space-between"
                paddingRight="8px"
              >
                <Text
                  variant="navbar"
                  transition="inherit"
                  _groupHover={{
                    color: itemActive ? undefined : "dune.700",
                  }}
                  color={itemActive ? "dune.100" : "dune.700"}
                  fontSize="14px"
                >
                  {text}
                </Text>
                {buttonElement}
              </Flex>
            ) : null}
          </Box>
        </HStack>
      </Tooltip>
    </PreloadLink>
  );
}
