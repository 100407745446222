import { Flex, Link, Spinner } from "@chakra-ui/react";
import { useComicReleasesData } from "../../../screens/reader/hooks/hookstate/useComicReleasesData";
import { Comic, Release } from "../../../types";
import { ImmutableObject, useHookstate } from "@hookstate/core";
import { useQuery } from "@tanstack/react-query";
import {
  GET_COMIC_RELEASES_QUERY_KEY,
  getComicReleases,
} from "../../../screens/reader/api/readerScreenApi";
import { rootComponentsStore } from "../../layouts/RootLayout";
import { ReleaseListCard } from "../../cards/ReleaseListCard";
import { useInvalidateQueries } from "../../../services/axiosInstance";
import { SortLanguageMenu } from "../../sortMenus/SortLanguageMenu";
import { useState } from "react";
import { useNavigateToReaderWithContext } from "../../../screens/reader/hooks/useNavigateToReaderWithContext";
import {
  NavigationTrigger,
  ReaderNavigationContext,
} from "../../../screens/reader/types";
import { useSlideInMenu } from "../hooks/useSlideInMenu";
import { useAccountData } from "../../../hooks/useAccountData";
import { UnlockableReleaseCard } from "../../cards/UnlockableReleaseCard";
import { useReleaseData } from "../../../screens/reader/hooks/hookstate/useReleaseData";

export function ReleasesList({
  comicData,
}: {
  comicData: ImmutableObject<Comic> | undefined;
}) {
  const inReader = useHookstate(rootComponentsStore.inReader).get();
  const { releaseData: readerReleaseData } = useReleaseData();
  const { comicReleasesData } = useComicReleasesData();
  const navigationContext =
    rootComponentsStore.slideInMenuConfig.get()?.navigationContext;

  const defaultLangId = readerReleaseData?.lang_id ?? comicData?.lang;
  const [language, setLanguage] = useState<string>(defaultLangId ?? "en");

  const comicReleasesQueryKey = [
    ...GET_COMIC_RELEASES_QUERY_KEY,
    comicData?.id.toString() ?? "",
    language ?? defaultLangId ?? "",
  ];

  const shouldReleasesBeFetched =
    !inReader ||
    (comicReleasesData &&
      (comicData?.id !== comicReleasesData[0]?.comic_id ||
        language !== comicReleasesData[0]?.lang_id));

  const { isLoading, data } = useQuery({
    enabled: shouldReleasesBeFetched,
    queryKey: comicReleasesQueryKey,
    queryFn: () => {
      return getComicReleases(comicData?.id ?? 0, language ?? defaultLangId);
    },
    refetchOnWindowFocus: false,
  });

  const invalidateData = useInvalidateQueries(comicReleasesQueryKey);

  const releases = !shouldReleasesBeFetched
    ? comicReleasesData
    : data?.data.payload.results.sort((a, b) => a.order - b.order);

  return (
    <Flex flexDirection="column" width="100%" height="100%" gap="24px">
      <SortLanguageMenu
        invalidate={invalidateData}
        filterState={language}
        onChange={setLanguage}
        comicLanguageIds={
          comicData?.comic_langs?.map((lang) => lang.lang_id) ?? []
        }
        initialLanguageId={comicData?.lang ?? ""}
      />
      {isLoading ? (
        <Flex alignItems="center" justifyContent="center" width="100%">
          <Spinner color="blaze.blaze" />
        </Flex>
      ) : (
        releases?.map((comicRelease) => (
          <ComicReleaseCard
            key={comicRelease.id}
            releaseData={comicRelease}
            comicData={comicData}
            activeReleaseId={readerReleaseData?.id}
            navigationContext={navigationContext}
          />
        ))
      )}
    </Flex>
  );
}

function ComicReleaseCard({
  releaseData,
  comicData,
  activeReleaseId,
  navigationContext,
}: {
  releaseData: ImmutableObject<Release>;
  comicData?: ImmutableObject<Comic>;
  activeReleaseId?: number;
  navigationContext?: ReaderNavigationContext;
}) {
  let updatedRelease = {
    ...releaseData,
    comic: comicData,
    title: `${releaseData.order}: ${releaseData.title}`,
  };

  const accountData = useAccountData();
  const { openReleaseInfoMenu } = useSlideInMenu();
  const { goToCTAUrl, navigationContext: readerNavigationContext } =
    useNavigateToReaderWithContext({
      ...navigationContext,
      trigger: NavigationTrigger.releasesListCard,
      releaseToNavigate: updatedRelease as Release,
    });

  if (accountData?.features["release_details"]) {
    return (
      <Link
        onClick={goToCTAUrl}
        key={releaseData.id}
        id={`release-${releaseData.id}`}
        aria-label={`Read ${releaseData.title}`}
      >
        <UnlockableReleaseCard
          fullWidth
          activeBorder={releaseData?.id === activeReleaseId}
          navigationContext={readerNavigationContext}
          release={updatedRelease as Release}
          display={{
            include_last_updated: true,
            include_total_pages: true,
            include_total_views: true,
            include_progress_meter: true,
          }}
        />
      </Link>
    );
  }

  return (
    <Link
      id={`release-${releaseData.id}`}
      key={releaseData.id}
      aria-label={`Read ${releaseData.title}`}
      onClick={() => {
        if (accountData?.features["release_details"]) {
          openReleaseInfoMenu(
            releaseData.key ?? "",
            comicData?.slug + "",
            readerNavigationContext,
          );
        } else {
          goToCTAUrl();
        }
      }}
      style={{ width: "100%" }}
    >
      <ReleaseListCard
        fixedHeight={116}
        fullWidth
        activeBorder={releaseData?.id === activeReleaseId}
        content={updatedRelease as Release}
        layout={{
          style: {
            context: "releases",
            mode: "grid",
            size: "c_1",
            rows: 1,
            corners_top: "right",
            corners_bottom: "right",
            section_is_inset: false,
          },
          display: {
            include_title: true,
            include_cover_image: true,
            include_last_updated: true,
            include_total_pages: true,
            include_total_views: true,
            include_progress_meter: true,
            include_share: true,
          },
        }}
      />
    </Link>
  );
}
