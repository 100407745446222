import { SharpCornerBox } from "./SharpCornerBox";
import { BoxProps } from "@chakra-ui/react";
import { ShoppingThickIcon } from "../../icons/ShoppingThickIcon";

interface LockedContentBadgeProps extends BoxProps {}

export function LockedContentBadge({ ...props }: LockedContentBadgeProps) {
  return (
    <SharpCornerBox
      corners={["topLeft", "bottomRight"]}
      background="blaze.blaze"
      borderWidth={0}
      {...props}
    >
      <ShoppingThickIcon boxSize="16px" />
    </SharpCornerBox>
  );
}
