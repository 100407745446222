import { Button, Text, Flex } from "@chakra-ui/react";
import { cloneElement, forwardRef } from "react";
import { AddedBubbleIcon } from "../icons/AddedBubbleIcon";

interface FilterTagButtonProps {
  popoverRef?: any;
  alt?: boolean;
  icon?: React.ReactElement;
  onClick?: () => void;
  text: string;
  isActive?: boolean;
  isFiltering?: boolean | string | undefined;
  activeFilters?: number;
  borderColor?: string;
}
export const FilterTagButton = forwardRef(
  (
    {
      alt,
      icon,
      onClick,
      text,
      isActive,
      isFiltering,
      activeFilters,
      popoverRef,
      ...props
    }: FilterTagButtonProps,
    ref,
  ) => {
    return (
      <Button
        ref={popoverRef || ref}
        variant={alt ? "filterTagAlt" : "filterTag"}
        gap={1.5}
        onClick={onClick}
        isActive={isActive}
        height="36px"
        minWidth="40px"
        {...props}
      >
        {icon &&
          cloneElement(icon, { w: "18px", h: "18px", color: "info.200" })}
        <Text variant="filterTag">{text}</Text>
        {isFiltering && (
          <>
            {typeof activeFilters === "number" && activeFilters >= 0 ? (
              <Flex
                w="16px"
                h="16px"
                m="1px"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                color="charcoal.charcoal"
                fontWeight="bold"
                fontSize="xs"
                background="blaze.300"
                lineHeight="15px"
                borderRadius="50%"
              >
                {activeFilters}
              </Flex>
            ) : (
              <AddedBubbleIcon w="18px" h="18px" color="blaze.300" />
            )}
          </>
        )}
      </Button>
    );
  },
);
