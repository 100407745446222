import { Button, Flex } from "@chakra-ui/react";
import { BookmarkAddedIcon } from "../icons/BookmarkAddedIcon";
import { BookmarkIcon } from "../icons/BookmarkIcon";
import { CTAButton } from "./CTAButton";

interface FavoriteButtonProps {
  active?: boolean;
  onClick?: () => void;
  noIcon?: boolean;
  small?: boolean;
  showBorder?: boolean;
}
export function LibraryCTAButton({
  active,
  onClick,
  noIcon,
  small,
  showBorder = true,
}: FavoriteButtonProps) {
  if (small) {
    return (
      <Button
        variant={showBorder ? (active ? "secondary" : "tertiary") : undefined}
        size="thin"
        aria-label="Add to library"
        _hover={{
          backgroundColor: "transparent",
        }}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          onClick?.();
        }}
        style={
          !showBorder
            ? {
                border: "none",
                padding: "0",
                backgroundColor: "transparent",
              }
            : undefined
        }
      >
        <Flex
          color={active ? "blaze.600" : "info.200"}
          _hover={{
            color: active ? "blaze.300" : "info.400",
          }}
        >
          {active ? (
            <BookmarkAddedIcon height="24px" width="24px" />
          ) : (
            <BookmarkIcon height="24px" width="24px" />
          )}
        </Flex>
      </Button>
    );
  }

  return (
    <CTAButton
      onClick={onClick}
      text={noIcon ? "" : active ? "remove" : "add"}
      icon={active ? <BookmarkAddedIcon /> : <BookmarkIcon />}
      active={active}
    />
  );
}
