import { Route } from "react-router-dom";
import NewsDetailsScreen from "./NewsDetailsScreen";
import NewsScreen from "./NewsScreen";

export const routes = (
  <>
    <Route path="news/details/:id/:slug?" Component={NewsDetailsScreen} />
    <Route path="news/:categorySlug?" Component={NewsScreen} index />
  </>
);
