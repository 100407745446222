import { useState, useCallback, useEffect } from "react";
import { rootComponentsStore } from "../components/layouts/RootLayout";

export enum ScrollDirection {
  Up = "up",
  Down = "down",
}

export const useScrollVisibility = (hideThreshold = 100) => {
  const [scrollDirection, setScrollDirection] = useState<ScrollDirection>(
    ScrollDirection.Up,
  );
  const [isHidden, setIsHidden] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [maxElementHeight, setMaxElementHeight] = useState(0);

  useEffect(() => {
    rootComponentsStore.isTopbarHidden.set(isHidden);
  }, [isHidden]);

  const handleScroll = useCallback(
    (scrollableRef: HTMLDivElement) => {
      const {
        scrollTop: scrollPosition,
        clientHeight,
        scrollHeight,
      } = scrollableRef;

      // Need to save the clientHeight when navbar is collapsed, so we can compare it later
      // to determine if user reached the bottom of the page
      if (clientHeight > maxElementHeight) {
        setMaxElementHeight(clientHeight);
      }

      const hasReachedMaxScroll =
        scrollPosition + maxElementHeight >= scrollHeight;

      // Check scroll direction
      if (!hasReachedMaxScroll) {
        if (
          scrollPosition > lastScrollY &&
          scrollPosition > hideThreshold &&
          scrollDirection !== ScrollDirection.Down
        ) {
          setScrollDirection(ScrollDirection.Down);
          setIsHidden(true); // Hide component when scrolling down
        } else if (
          scrollPosition < lastScrollY &&
          scrollDirection !== ScrollDirection.Up
        ) {
          // Buffer the un-hiding to increase smoothness of touch controls
          if (
            scrollPosition < hideThreshold ||
            lastScrollY - scrollPosition >= hideThreshold / 2
          ) {
            setScrollDirection(ScrollDirection.Up);
            setIsHidden(false); // Show component when scrolling up
          }
        }

        // Update last scroll position
        setLastScrollY(scrollPosition);
      }
    },
    [lastScrollY, scrollDirection, hideThreshold],
  );

  return {
    scrollDirection,
    isHidden,
    setIsHidden,
    handleScroll, // This is the function you can use on the scrollable element
  };
};
