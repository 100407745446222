import {
  Image,
  Text,
  Flex,
  Divider,
  AbsoluteCenter,
  Box,
} from "@chakra-ui/react";
import { ChatSectionType } from "../../../hooks/useChatSchedule";
import { CountdownTimer } from "../../time/CountdownTimer";
import {
  getRelativeTimeFromTimestamp,
  sqlDateToTimestamp,
} from "../../../utils/time";
import { LiveIndicator } from "../../chat/components/LiveIndicator";

const color = "rgba(255, 255, 255, 0.05)";
const hoverColor = "rgba(255, 255, 255, 0.1)";
const defaultBg = `linear-gradient(0deg, ${color} 0%, ${color} 100%), radial-gradient(100% 100% at 50% 100%, rgba(255, 255, 255, 0.15) 0%, transparent 75%);`;
const hoverBg = `linear-gradient(0deg, ${hoverColor} 0%, ${hoverColor} 100%), radial-gradient(100% 100% at 50% 100%, rgba(255, 255, 255, 0.15) 0%, transparent 75%);`;

export function ChatSection({
  onClick,
  event,
  dividerText,
  isActive = false,
  isLive = false,
  isNext = false,
  showTime = true,
}: {
  onClick: (event: ChatSectionType) => void;
  event: ChatSectionType;
  dividerText?: string;
  isActive?: boolean;
  isLive?: boolean;
  isNext?: boolean;
  showTime?: boolean;
}) {
  const startTimestamp = event.start_time
    ? sqlDateToTimestamp(event.start_time)
    : 0;
  const endTimestamp = event.end_time
    ? sqlDateToTimestamp(event.end_time)
    : Infinity;

  return (
    <>
      <Flex
        flexDirection="column"
        padding="16px"
        borderRadius="16px"
        gap="12px"
        background={defaultBg}
        border="1px solid"
        borderColor={isActive ? "blaze.300" : "charcoal.charcoal"}
        _hover={{ background: hoverBg }}
        transition="background 0.2s ease-in-out"
        cursor="pointer"
        onClick={() => onClick?.(event)}
      >
        <Flex gap="8px" position="relative" alignItems="center">
          <Image
            borderRadius="30%"
            src={event.image_small_url}
            width="80px"
            height="80px"
            alt={event.display_name}
          />
          <Flex flexDirection="column" gap="8px">
            {isLive && <LiveIndicator animate={false} />}
            {isNext && showTime && (
              <CountdownTimer
                context="event"
                targetTimestamp={isNext ? startTimestamp : endTimestamp}
              />
            )}
            <Flex flexDirection="column" alignItems="space-between" gap="4px">
              <Flex flexDirection="column">
                <Text variant="chatEventTitle">{event.display_name}</Text>
                <Text variant="chatEventSubtitle">{event.subtitle}</Text>
              </Flex>
              {!isLive && !isNext && showTime && (
                <Text variant="chatEventSubtitle" color="blaze.300">
                  {getRelativeTimeFromTimestamp(startTimestamp)}
                </Text>
              )}
            </Flex>
          </Flex>
        </Flex>

        {(isLive || isNext) && (
          <>
            <Divider colorScheme="gray" borderColor="gray" />
            <Text variant="chatEventDescription">{event.description}</Text>
          </>
        )}
      </Flex>

      {dividerText && (
        <Box position="relative" margin="10px 0px">
          <Divider colorScheme="gray" />
          <AbsoluteCenter bg="charcoal.charcoal" px="16px" height="20px">
            <Text variant="chatEventSubtitle">{dividerText}</Text>
          </AbsoluteCenter>
        </Box>
      )}
    </>
  );
}
