import { ImageProps, Image, Box, BoxProps } from "@chakra-ui/react";

export function NewsCardImage({
  imageProps,
  boxProps,
}: {
  imageProps?: ImageProps;
  boxProps?: BoxProps;
}) {
  return (
    <Box
      width="100%"
      borderRadius="16px"
      aspectRatio="16/9"
      height="min-content"
      justifyContent="center"
      overflow="hidden"
      border="1px solid"
      borderColor="transparent.white.10"
      boxShadow="0px 12px 14px 0px rgba(0, 0, 0, 0.25)"
      _hover={{
        borderColor: "transparent.white.50",
        boxShadow: "0px 12px 28px 0px rgba(0, 0, 0, 0.20)",
        cursor: "pointer",
      }}
      transition="all .3s ease-in"
      {...boxProps}
    >
      <Image loading="lazy" margin="auto" objectFit="cover" {...imageProps} />
    </Box>
  );
}
