import { Button, Flex, Text } from "@chakra-ui/react";
import { menuTitles } from "../../../screens/reader/components/menuTitles";
import { CloseCircleIcon } from "../../icons/CloseIcon";
import { CaretLeftLargeIcon } from "../../icons/CaretLeftLargeIcon";

export function SlideInHeader({
  handleBack,
  openMenu,
  onCloseMenu,
}: {
  handleBack?: () => void;
  onCloseMenu?: () => void;
  openMenu: string;
}) {
  return (
    <Flex
      position="absolute"
      top={0}
      left={0}
      zIndex={2}
      borderBottom="1px solid"
      borderColor="transparent.white.10"
      p="16px 24px "
      width="100%"
      justifyContent="space-between"
      alignItems="center"
      background="#141517CC"
      backdropFilter="blur(16px)"
      boxShadow="0px 4px 24px 0px rgba(0, 0, 0, 0.40)"
      style={{ scrollbarWidth: "thin" }}
    >
      {handleBack ? (
        <Button
          marginLeft="-10px"
          variant="iconOnly"
          aria-label="Close the slide in menu"
          onClick={handleBack}
        >
          <CaretLeftLargeIcon boxSize="24px" />
        </Button>
      ) : null}
      <Text
        fontSize="20px"
        fontWeight="600"
        lineHeight="26px"
        fontFamily="Bricolage Grotesque"
        color="dune.dune"
        width="100%"
      >
        {openMenu && menuTitles?.[openMenu]}
      </Text>
      <Button
        variant="iconOnly"
        aria-label="Close the slide in menu"
        onClick={onCloseMenu}
      >
        <CloseCircleIcon w="24px" h="24px" />
      </Button>
    </Flex>
  );
}
