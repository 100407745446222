import { useP2PSettings } from "../../hooks/useP2PSettings";
import { Fragment } from "../../../../types";
import { useReleaseData } from "../../hooks/hookstate/useReleaseData";

function absolutePoint(
  point: { x: number; y: number },
  size: { width: number; height: number },
) {
  const x = point.x * size.width;
  const y = point.y * size.height;
  return `${x},${y}`;
}

interface P2POverlayProps {
  activeFragment: Fragment;
  fragments: Fragment[];
  width: number;
  height: number;
}
export function P2POverlay({
  activeFragment,
  fragments,
  width,
  height,
}: P2POverlayProps) {
  const { releaseData } = useReleaseData();
  const augmentBlur = releaseData?.augment_blur;
  const { p2pOverlayOpacity } = useP2PSettings();

  if (!activeFragment) return null;
  return (
    <svg
      width="100%"
      height="100%"
      style={{ position: "absolute" }}
      viewBox={`0 0 ${width} ${height}`}
    >
      <defs>
        {augmentBlur && (
          <filter id="fragmentBlur">
            <feGaussianBlur in="SourceGraphic" stdDeviation={augmentBlur} />
          </filter>
        )}
        <mask id={"mask-" + activeFragment?.page_id}>
          <rect width={width} height={height} fill="white" />
          {fragments?.map((fragment) => {
            const path =
              typeof fragment.path === "string"
                ? JSON.parse(fragment.path)
                : fragment.path;
            return (
              <polygon
                id={`${fragment.entity_type}-${fragment.id}`}
                key={`${fragment.entity_type}-${fragment.id}`}
                points={path
                  ?.map((p: { x: number; y: number }) =>
                    absolutePoint(p, { width, height }),
                  )
                  .join(" ")}
                style={{
                  opacity: activeFragment.id === fragment.id ? 1 : 0,
                  filter: augmentBlur ? `url(#fragmentBlur)` : "none",
                  transition: "all 250ms ease-in",
                  willChange: "opacity",
                }}
              />
            );
          })}
        </mask>
      </defs>
      <rect
        width={width}
        height={height}
        style={{
          fill: "black",
          opacity: p2pOverlayOpacity / 100,
        }}
        mask={`url(#mask-${activeFragment?.page_id})`}
      />
    </svg>
  );
}
