import {
  Flex,
  VStack,
  Grid,
  GridItem,
  Text,
  useBreakpoint,
} from "@chakra-ui/react";
import { useMemo } from "react";
import { useMobileBreakpoint } from "../../utils/useBreakpoints";
import { Comic, Section, SectionLayout } from "../../types";
import { RELEASE_CALENDAR_MAX_WIDTH } from "./constants";
import { t } from "i18next";
import { ContentCard } from "./ContentCard";

interface ComicReleaseCalendarGridProps {
  comics: Comic[];
  day: string;
  layout: SectionLayout;
  sectionIndex?: number;
  section?: Section;
}

export function ComicReleaseCalendarGrid({
  comics,
  day,
  layout,
  sectionIndex,
  section,
}: ComicReleaseCalendarGridProps) {
  const isSmallMobile = useMobileBreakpoint();

  const breakpoint = useBreakpoint({ ssr: false });

  const columnCount = useMemo(() => {
    if (["xs", "sm"].includes(breakpoint)) return 1;
    else if (["md", "lg"].includes(breakpoint)) return 2;
    else if (["xl", "2xl"].includes(breakpoint)) return 3;
    else return 3;
  }, [breakpoint]);

  const maxItems = useMemo(() => {
    if (["xs", "sm"].includes(breakpoint)) return 3;
    else if (["md", "lg"].includes(breakpoint)) return 4;
    else if (["xl", "2xl"].includes(breakpoint)) return 6;
    else return 6;
  }, [breakpoint]);

  const numberOfComics = Math.min(comics.length, maxItems);

  const emptyGridSpaces = maxItems - comics.length;
  const shouldShowEmptyRow = isSmallMobile || numberOfComics <= maxItems / 2;

  return (
    <Flex flex="0 0 100%" width="100%">
      <VStack width="100%" align={"start"} pr={4}>
        {numberOfComics === 0 ? (
          <EmptyCalendarRowContainer day={day} />
        ) : (
          <Grid
            templateColumns={`repeat(${columnCount}, 1fr)`}
            gap={4}
            autoRows="1fr"
          >
            {comics.slice(0, maxItems).map((comic, index) => (
              <ContentCard
                content={comic}
                layout={layout}
                key={comic.id}
                index={index}
                sectionIndex={sectionIndex}
                section={section}
                dontOpenInfo
              />
            ))}
            {emptyGridSpaces > 0 && shouldShowEmptyRow && (
              <GridItem
                colSpan={{ base: 1, md: emptyGridSpaces }}
                rowSpan={{ base: emptyGridSpaces, md: 1 }}
              >
                <EmptyCalendarRowContainer day={day} />
              </GridItem>
            )}
          </Grid>
        )}
        {isSmallMobile && (
          <Text textColor="info.200" fontSize="10px" alignSelf="center">
            {t("components.cards.contentReleaseCalendar.showingCards", {
              cardsShown: numberOfComics,
              totalCards: comics.length,
            })}
          </Text>
        )}
      </VStack>
    </Flex>
  );
}

interface EmptyCalendarRowContainerProps {
  day: string;
}

export function EmptyCalendarRowContainer({
  day,
}: EmptyCalendarRowContainerProps) {
  return (
    <Flex
      bg="black"
      height="100%"
      width="100%"
      maxWidth={RELEASE_CALENDAR_MAX_WIDTH + "px"}
      alignItems="center"
      justifyContent="center"
      borderRadius="16"
      flex="1"
    >
      <VStack pr={{ md: 2 }}>
        <Text variant="cardLargeHeader" fontSize={30}>
          {/* Party emoji */}
          {String.fromCodePoint(0x1f389)}
        </Text>
        <Text textColor="neutral.200" variant="cardSubtitle">
          Stay tuned, more releases coming on {day}!
        </Text>
      </VStack>
    </Flex>
  );
}
