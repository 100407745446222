import {
  Box,
  BoxProps,
  Flex,
  Image,
  useMultiStyleConfig,
} from "@chakra-ui/react";
import { EmblaCarouselType } from "embla-carousel";
import { useNavigate } from "react-router";
import {
  ContentSectionCustomCard,
  GCChannel,
  GCStream,
  Section,
} from "../../types";
import { CustomCardFacet } from "./components/CustomCardFacet";
import { CONTEXT_ENTITY_TYPES } from "./constants";
import { useAccountData } from "../../hooks/useAccountData";
import { CustomCardLeavingSoonBadge } from "./components/LeavingSoonBadge";
import { FUNNEL_TYPES } from "../funnel/utils/contants";
import { useGetNavigationContext } from "../../hooks/useGetNavigationContext";
import { FunnelContextArea } from "../funnel/types";
import { setFunnelModalConfig } from "../funnel/FunnelModal";
import { useNavigateToReaderWithContext } from "../../screens/reader/hooks/useNavigateToReaderWithContext";
import { useToggleArtistFollow } from "./hooks/useToggleArtistFollow";

export interface CustomCardProps extends BoxProps {
  coverImage: string;
  contentCard: ContentSectionCustomCard;
  isHidden?: boolean;
  emblaApi?: EmblaCarouselType;
  cardResponsiveWidths?: Record<string, string> | string;
  cardResponsiveHeights?: Record<string, string> | string;
  cardResponsiveBorderRadius?: Record<string, string>;
  fetchPriority?: "high" | "low";
  index?: number;
  sectionIndex?: number;
  section?: Section;
}

export const defaultCardResponsiveHeights: Record<string, string> = {
  base: "260px",
  sm: "336px",
  md: "421px",
  lg: "398px",
  xl: "398px",
  "2xl": "437px",
  "3xl": "555px",
  "4xl": "828px",
};

export function CustomCard({
  coverImage,
  contentCard,
  isHidden = false,
  emblaApi,
  cardResponsiveWidths,
  cardResponsiveHeights,
  cardResponsiveBorderRadius,
  fetchPriority,
  index,
  sectionIndex,
  section,
  ...props
}: CustomCardProps) {
  const defaultCardResponsiveWidths = {
    base: "260px",
    sm: "336px",
    md: "632px",
    lg: "598px",
    xl: "708px",
    "2xl": "777px",
    "3xl": "988px",
    "4xl": "1472px",
  };

  const defaultCardBorderRadius = {
    base: "16px",
    md: "17px",
    lg: "18px",
    xl: "18px",
    "2xl": "20px",
    "3xl": "25px",
    "4xl": "38px",
  };

  const cardWidths = cardResponsiveWidths || defaultCardResponsiveWidths;
  const cardHeights = cardResponsiveHeights || defaultCardResponsiveHeights;
  const cardBorderRadius =
    cardResponsiveBorderRadius || defaultCardBorderRadius;

  // this is needed to fix a border radius issue on the facet ribbon
  const cardOverlayBorderRadius: Record<string, string> = {};
  for (const [key, value] of Object.entries(cardBorderRadius)) {
    cardOverlayBorderRadius[key] = `calc(${value} - 1px)`;
  }

  const isFeatureCard = contentCard.card_type === "featured-row-carousel";

  const navigate = useNavigate();
  const { goToCTAUrl } = useNavigateToReaderWithContext({
    comicToNavigate:
      contentCard.context_entity_type_id === CONTEXT_ENTITY_TYPES.comic &&
      contentCard.comic
        ? (contentCard.comic ?? undefined)
        : undefined,
    releaseToNavigate:
      contentCard.context_entity_type_id === CONTEXT_ENTITY_TYPES.release &&
      contentCard.release
        ? (contentCard.release ?? undefined)
        : undefined,
    cardPosition: index,
    sectionPosition: sectionIndex,
    section,
  });
  const accountData = useAccountData();
  const navigationContext = useGetNavigationContext({
    contextArea: FunnelContextArea.addToLibrary,
    comic: contentCard.comic ? contentCard.comic : undefined,
  });

  const creatorCacheKey = `${contentCard.artist?.entity_type}-${contentCard.artist?.id}`;
  const handleToggleCreatorFollow = useToggleArtistFollow(creatorCacheKey);
  const handleClickCard = () => {
    if (contentCard.cta_type === "url" && contentCard.cta_url) {
      if (contentCard.cta_url.includes("/stream")) {
        const channels = accountData?.home_tab_channels;
        const streams = channels?.flatMap(
          (channel: GCChannel) => channel.streams,
        );
        const urlChunks = contentCard.cta_url?.split("/") ?? [];
        const urlStreamId = urlChunks[urlChunks.length - 1];
        const newActiveChannel = streams?.find((stream: GCStream) => {
          return urlStreamId === stream.id;
        });

        navigate(`/channel/${newActiveChannel?.slug}`);
      } else if (contentCard.cta_url.includes("news/details")) {
        navigate(new URL(contentCard.cta_url).pathname);
      } else {
        const linkUrl = new URL(contentCard.cta_url);
        window.location.href = linkUrl.pathname + linkUrl.search;
      }
    } else if (
      contentCard.context_entity_type_id === CONTEXT_ENTITY_TYPES.artist &&
      contentCard.artist
    ) {
      if (contentCard.cta_type === "notify") {
        handleToggleCreatorFollow();
      } else {
        navigate(new URL(contentCard.artist.url).pathname);
      }
    } else if (
      contentCard.context_entity_type_id === CONTEXT_ENTITY_TYPES.comic &&
      contentCard.comic
    ) {
      // this check is needed because "notify" cta_type should not open the reader
      if (contentCard.cta_type === "notify" && contentCard.comic.url) {
        setFunnelModalConfig({
          type: FUNNEL_TYPES.addToLibrary,
          options: {
            modalContext: FunnelContextArea.addToLibrary,
            cacheKey: `${contentCard.comic.entity_type}-${contentCard.comic.id}`,
            navigationContext,
          },
        });
      } else if (contentCard.comic.read_url) {
        goToCTAUrl();
      }
    } else if (
      contentCard.context_entity_type_id === CONTEXT_ENTITY_TYPES.release &&
      contentCard.release?.read_url
    ) {
      goToCTAUrl();
    }
  };

  const handleClickHiddenCard = () => {
    emblaApi?.scrollNext();
  };

  const leaving_soon_date = contentCard?.comic?.leaving_soon_date;

  const variant = isFeatureCard ? "featureCard" : "heroCard";

  const cardFacetStyles = useMultiStyleConfig("CustomCardFacet", { variant });

  return (
    <Flex
      direction="column"
      position="relative"
      w={cardWidths}
      h={cardHeights}
      {...props}
    >
      <Image
        className={`image-with-priority`}
        loading={
          fetchPriority === "low"
            ? "lazy"
            : fetchPriority === "high"
              ? "eager"
              : undefined
        }
        src={coverImage}
        w={cardWidths}
        h={cardHeights}
        maxH="inherit"
        maxW="inherit"
        borderRadius={cardBorderRadius}
        objectFit="cover"
        onClick={
          !isHidden
            ? (e) => {
                e.stopPropagation();
                e.preventDefault();
                handleClickCard();
              }
            : undefined
        }
        cursor="pointer"
        outline="1px solid"
        outlineColor="transparent.white.20"
        outlineOffset="-1px"
        alt={contentCard.cta_url ?? ""}
      />
      <Box position="absolute" top={0} left={0}>
        {contentCard.comic_facet_group_option_id && !leaving_soon_date && (
          <CustomCardFacet
            borderRadius={
              isFeatureCard
                ? `${cardBorderRadius.base} 0 8px 0`
                : cardBorderRadius
            }
            variant={variant}
            facetId={contentCard.comic_facet_group_option_id}
          />
        )}
        {leaving_soon_date && (
          <CustomCardLeavingSoonBadge
            leavingSoonDate={leaving_soon_date}
            borderRadius={
              isFeatureCard
                ? `${cardBorderRadius.base} 0 8px 0`
                : cardBorderRadius
            }
            fontSize={cardFacetStyles.text.fontSize as string}
            padding={!isFeatureCard ? "8px 16px 8px 16px" : undefined}
          />
        )}
      </Box>
      <Box
        position="absolute"
        top={0}
        left={0}
        w="100%"
        h="100%"
        bg="transparent.black.60"
        borderRadius={cardOverlayBorderRadius}
        cursor="pointer"
        opacity={isHidden && !isFeatureCard ? 1 : 0}
        transition="opacity 0.5s ease"
        pointerEvents={isHidden && !isFeatureCard ? "auto" : "none"}
        onClick={handleClickHiddenCard}
      />
    </Flex>
  );
}
