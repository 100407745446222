import { useHookstate } from "@hookstate/core";
import { useParams } from "react-router";
import { appStore } from "../../../appStore";

export function useNewsCategories() {
  const { categorySlug } = useParams();
  const newsCategories = useHookstate(appStore.init.news_categories).get();
  const activeCategory = newsCategories?.find(
    (category) => category.slug === categorySlug,
  );

  return { newsCategories, activeCategory };
}
