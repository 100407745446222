import { Flex } from "@chakra-ui/react";
import { t } from "i18next";
import { StripePayment, StripePaymentConfigTypes } from "./StripePayment";
import { useInvalidateQueries } from "../../../services/axiosInstance";
import { ImmutableObject } from "@hookstate/core";
import { Release, ServerResponse } from "../../../types";
import { FunnelComponentProps } from "../../funnel/types";
import { unlockRelease } from "../api/paymentApi";
import { usePaymentMutation } from "../hooks/usePaymentMutation";
import { useUserData } from "../../../hooks/useUserData";
import axios from "axios";
import { GET_COMIC_RELEASES_QUERY_KEY } from "../../../screens/reader/api/readerScreenApi";
import { useReleaseDataQueryKey } from "../../../screens/reader/hooks/useReleaseDataQueryKey";

interface PurchaseReleaseModalFooterProps {
  release: ImmutableObject<Release>;
}

export function PurchaseReleaseModalFooter({
  release,
  isModal,
  onClose,
}: FunnelComponentProps & PurchaseReleaseModalFooterProps) {
  const { userData } = useUserData();
  const isGoldUser = userData?.gold ?? false;
  const releaseDataQueryKey = useReleaseDataQueryKey();

  const pricePoint =
    (isGoldUser
      ? (release.ppb_gold_price_point ?? release.ppb_price_point)
      : release.ppb_price_point) ?? 1;

  const price = pricePoint / 100;

  const onSuccess = () => {
    invalidateReleases();
    onClose();
  };

  const invalidateReleases = useInvalidateQueries(
    [...GET_COMIC_RELEASES_QUERY_KEY, release.comic?.id, release.lang_id],
    ["fetchedComicReleases", release.comic?.id],
    releaseDataQueryKey,
    ["releaseDetailsRelease", release.key],
  );

  const mutationFn = (stripePaymentConfig: StripePaymentConfigTypes) => {
    return unlockRelease(release.id, {
      price_point_id: pricePoint,
      is_gold: isGoldUser,
      raw_price: pricePoint / 100,
      stripeToken: stripePaymentConfig.stripeToken,
      ownerPaymentServiceTokenId:
        stripePaymentConfig.ownerPaymentServiceTokenId,
    });
  };

  const onError = (error: Error) => {
    let errorMessage = t("components.payments.purchaseRelease.purchaseDecline");
    if (axios.isAxiosError<ServerResponse<string>>(error)) {
      errorMessage = error.response?.data?.payload.results || errorMessage;
    }

    return errorMessage;
  };

  const {
    mutate: purchaseRelease,
    failedCard,
    cardValidationError,
    waiting,
  } = usePaymentMutation(mutationFn, onSuccess, onError);

  return (
    <Flex flexDirection="column" width="100%" gap="24px">
      <StripePayment
        waiting={waiting}
        completePurchase={purchaseRelease}
        submitButtonText={`${t("components.payments.pdf.purchaseReleaseFor")} $${price}`}
        responseError={cardValidationError}
        failedCard={failedCard}
        isModal={isModal}
      />
    </Flex>
  );
}
