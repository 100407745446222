import { CloseIcon } from "@chakra-ui/icons";
import {
  Flex,
  Text,
  Button,
  AbsoluteCenter,
  Box,
  Divider,
} from "@chakra-ui/react";
import { ChatSectionType } from "../../hooks/useChatSchedule";
import { motion } from "framer-motion";
import { useIsSmallBreakpoint } from "../../utils/useBreakpoints";
import {
  SCHEDULE_PADDING_RIGHT,
  SCHEDULE_WIDTH,
} from "../../screens/globalChat/ChatScreen";
import { NAVBAR_HEIGHT } from "../../screens/root/constants";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useUserData } from "../../hooks/useUserData";
import { setFunnelModalConfig } from "../funnel/FunnelModal";
import { FUNNEL_TYPES } from "../funnel/utils/contants";
import { FunnelContextArea } from "../funnel/types";
import { useGetNavigationContext } from "../../hooks/useGetNavigationContext";
import { ChatSection } from "./components/ChatSection";

export function ChatSchedule({
  activeChatSection,
  isOpen = true,
  setIsOpen,
  rooms,
  liveEvents,
  futureEvents,
}: {
  activeChatSection?: ChatSectionType;
  isOpen?: boolean;
  setIsOpen: (isOpen: boolean) => void;
  rooms: ChatSectionType[];
  liveEvents: ChatSectionType[];
  futureEvents: ChatSectionType[];
}) {
  const { userData } = useUserData();
  const isSmall = useIsSmallBreakpoint();

  const [display, setDisplay] = useState("flex");
  useEffect(() => {
    if (!isOpen) {
      const timeout = setTimeout(() => {
        setDisplay("none");
      }, 200);

      return () => clearTimeout(timeout);
    } else {
      setDisplay("flex");
    }
  }, [isOpen]);

  const navigationContext = useGetNavigationContext({
    contextArea: FunnelContextArea.chatSchedule,
  });
  const navigate = useNavigate();
  const handleClick = (event: ChatSectionType) => {
    if (event.requires_sub && !userData?.gold) {
      setFunnelModalConfig({
        type: FUNNEL_TYPES.subscription,
        options: {
          modalContext: FunnelContextArea.chatSchedule,
          navigationContext,
          onAuthenticated: () => {
            navigate(`/chat/${event.uuid}`);
            if (isSmall) setIsOpen(false);
          },
          skipAuthentication: true,
        },
      });
    } else if (event.requires_auth && !userData) {
      setFunnelModalConfig({
        type: FUNNEL_TYPES.signup,
        options: {
          modalContext: FunnelContextArea.chatSchedule,
          navigationContext,
          onAuthenticated: () => {
            navigate(`/chat/${event.uuid}`);
            if (isSmall) setIsOpen(false);
          },
        },
      });
    } else {
      navigate(`/chat/${event.uuid}`);
      if (isSmall) setIsOpen(false);
    }
  };

  return (
    <Flex
      as={motion.div}
      display={display}
      position={isSmall ? "absolute" : undefined}
      top={isSmall ? -NAVBAR_HEIGHT + "px" : "40px"}
      zIndex={isSmall ? 9 : undefined}
      right={isSmall ? "0px" : SCHEDULE_PADDING_RIGHT}
      initial={{ opacity: 1 }}
      animate={{
        opacity: isOpen ? 1 : 0,
        transition: {
          delay: !isSmall && isOpen ? 0.2 : 0,
          duration: 0.2,
        },
      }}
      pointerEvents={isOpen ? "all" : "none"}
      width={isSmall ? "100dvw" : SCHEDULE_WIDTH}
      height={isSmall ? "100dvh" : "100%"}
      flexDirection="column"
      borderRadius={isSmall ? "0px" : "16px"}
      background="charcoal.charcoal"
      backdropFilter="blur(12px)"
      border="1px solid"
      borderColor="transparent.white.20"
    >
      <Flex
        borderTopRadius="16px"
        borderBottom="1px solid"
        borderColor="transparent.white.10"
        padding={isSmall ? "16px" : "24px"}
        justifyContent="space-between"
        alignItems="center"
        boxShadow="0px 4px 24px 0px var(--chakra-colors-transparent-black-40)"
        backdropFilter="blur(18px)"
      >
        <Text variant="chatScheduleHeader">Chat Rooms</Text>
        <Button
          p="0px"
          variant="ghostIcon"
          onClick={() => setIsOpen(false)}
          aria-label="Close chat schedule"
        >
          <CloseIcon boxSize="14px" />
        </Button>
      </Flex>
      <Flex flexDirection="column" gap="16px" padding="24px" overflowY="auto">
        {rooms.map((room) => (
          <ChatSection
            isActive={room.uuid === activeChatSection?.uuid}
            onClick={handleClick}
            showTime={false}
            event={room}
          />
        ))}
        {liveEvents.length > 0 && (
          <Box position="relative" margin="10px 0px">
            <Divider colorScheme="gray" />
            <AbsoluteCenter bg="charcoal.charcoal" px="16px" height="20px">
              <Text variant="chatEventSubtitle">Live Event</Text>
            </AbsoluteCenter>
          </Box>
        )}
        {liveEvents.map((event) => (
          <ChatSection
            key={event.id}
            isActive={event.uuid === activeChatSection?.uuid}
            onClick={handleClick}
            event={event}
            isLive
          />
        ))}
        {futureEvents.length > 0 && (
          <Box position="relative" margin="10px 0px">
            <Divider colorScheme="gray" />
            <AbsoluteCenter bg="charcoal.charcoal" px="16px" height="20px">
              <Text variant="chatEventSubtitle">Next Event</Text>
            </AbsoluteCenter>
          </Box>
        )}
        {futureEvents.map((event, i) => (
          <ChatSection
            key={event.id}
            isActive={event.uuid === activeChatSection?.uuid}
            onClick={handleClick}
            event={event}
            isNext={i === 0}
            dividerText={
              i === 0 && futureEvents.length > 1 ? "Upcoming events" : ""
            }
          />
        ))}
      </Flex>
    </Flex>
  );
}
