import { Image, Text, Flex, Link, Button } from "@chakra-ui/react";
import { useIsSmallBreakpoint } from "../../utils/useBreakpoints";
import { t } from "i18next";
import { AppStoreIcon } from "../icons/AppStoreIcon";
import { PlayStoreIcon } from "../icons/PlayStoreIcon";

export function DownloadOurApp() {
  const isSmallBreakpoint = useIsSmallBreakpoint();
  return (
    <Flex
      height="fit-content"
      width="100%"
      alignItems="center"
      justifyContent="center"
      flexDirection={isSmallBreakpoint ? "column-reverse" : "row"}
      gap="32px"
    >
      <Image
        alt={t("components.downloadOurApp.downloadOurApp")}
        maxWidth={isSmallBreakpoint ? "100%" : "calc(100% - 500px)"}
        src={`${window.gc.global.assets_url}/static/images/web-ui/goldAppUpsell.png`}
        boxShadow="25px 25px 50px 0 var(--chakra-colors-charcoal-charcoal) inset, -25px -25px 50px 0 var(--chakra-colors-charcoal-charcoal) inset"
        style={{
          maskImage:
            "linear-gradient(to bottom, rgba(0,0,0,1), rgba(0,0,0,1), rgba(0,0,0,0.1))",
        }}
      />
      <Flex
        maxWidth={isSmallBreakpoint ? "100%" : "500px"}
        height="fit-content"
        pl={isSmallBreakpoint ? "0px" : "40px"}
        flexDirection="column"
        gap="16px"
      >
        <Text
          variant="faqTitle"
          textAlign={isSmallBreakpoint ? "center" : "left"}
          fontSize="36px"
          lineHeight="130%"
          fontWeight={600}
          sx={{
            textWrap: "balance",
          }}
        >
          {t("components.downloadOurApp.downloadOurApp")}
        </Text>
        <Text
          variant="faqAnswer"
          textAlign={isSmallBreakpoint ? "center" : "left"}
        >
          {t("components.downloadOurApp.availableOn")}
        </Text>
        <Flex
          justifyContent={isSmallBreakpoint ? "center" : "flex-start"}
          gap="16px"
        >
          <Link href="https://itunes.apple.com/us/app/keynote/id6444957733">
            <Button
              variant="tertiary"
              name={t("components.downloadOurApp.getOnApple")}
              aria-label={t("components.downloadOurApp.getOnApple")}
            >
              <AppStoreIcon width="96px" height="24px" />
            </Button>
          </Link>
          <Link href="https://play.google.com/store/apps/details?id=com.globalcomix.mobileapp">
            <Button
              variant="tertiary"
              name={t("components.downloadOurApp.getOnGoogle")}
              aria-label={t("components.downloadOurApp.getOnGoogle")}
            >
              <PlayStoreIcon width="103px" height="24px" />
            </Button>
          </Link>
        </Flex>
      </Flex>
    </Flex>
  );
}
